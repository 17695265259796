/**
 * Events, emitted with `triggerCustomEvent`, targetting the users.
 */
export const CUSTOM_EVENTS = {
  BRAZE_INIT: 'ninja/brazeInit',
  LAQUESIS_READY: 'laquesis/ready',
  LAQUESIS_ASSIGNEMENTS_READY: 'laquesis/assignmentsReady',
  LAQUESIS_FLAG_ASSIGNMENT: 'laquesis/flagAssignment',
  LAQUESIS_EXP_ASSIGNMENT: 'laquesis/experimentAssignment',
  LAQUESIS_EXP_IMPRESSION: 'laquesis/experimentImpression',
  LAQUESIS_SURVEY_SHOW: 'laquesis/surveyShow',
  LAQUESIS_SURVEY_CLOSE: 'laquesis/surveyClose',
};
