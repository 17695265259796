import { getLogger } from '@/logger';
import { Task, TaskEvent, TaskEventCallback, TaskOptions } from '@/types/tasks';
// import { MinHeap } from './BinaryHeap';
import { StablePriorityQueue } from './StablePriorityQueue';
import { TASK_PRIORITY } from './const';

const logger = getLogger('TaskQueue');

/**
 * Class: FunctionQueue
 *
 * A priority-based function queue system with support for concurrency limits,
 * task retries, timeouts, and dynamic task prioritization. Tasks are managed
 * in a MinHeap for optimized priority-based execution.
 */
export class TaskQueue {
  private queue: StablePriorityQueue<Task>; // Priority queue (MinHeap) to store tasks
  private runningCount: number; // Tracks the number of running tasks
  private concurrencyLimit: number; // Limits how many tasks can run concurrently
  private isPaused: boolean; // Controls whether the queue is paused
  private maxQueueSize: number; // Maximum number of tasks allowed in the queue
  private eventListeners: Partial<Record<TaskEvent, TaskEventCallback[]>>; // Stores event listeners for task events
  private runTimeout: NodeJS.Timeout | null = null; // Timeout to control debounce behavior in `run`
  private nextTaskId: number; // each task has autoincrementing id
  private nextPriority = TASK_PRIORITY.LOW;
  private runDebounceTimeMs = 50; // how often to execute `run()`

  /**
   * Constructor: Initialize the function queue with concurrency and max queue size.
   *
   * @param {number} concurrencyLimit - The maximum number of tasks to run concurrently (default is 1).
   * @param {number} maxQueueSize - Maximum allowed tasks in the queue (default is infinite).
   */
  constructor(concurrencyLimit: number = 1, maxQueueSize: number = Infinity) {
    this.queue = new StablePriorityQueue<Task>((a, b) => {
      return a.priority - b.priority;
    }); // Create a new MinHeap for priority queueing
    this.runningCount = 0; // No tasks running initially
    this.concurrencyLimit = concurrencyLimit; // Set the concurrency limit
    this.isPaused = false; // Queue starts in a running state
    this.maxQueueSize = maxQueueSize; // Set the maximum queue size
    this.eventListeners = {}; // Initialize event listeners object
    this.nextTaskId = 1;
  }

  /**
   * Enqueue a new task into the queue.
   *
   * @param {TaskOptions} options - The task options including the function, priority, retries, and timeout.
   */
  enqueue({ fn, priority = 0, retries = 0, timeout = null, taskId = this.generateTaskId() }: TaskOptions): void {
    // Check if the queue has exceeded its maximum size
    if (this.queue.size >= this.maxQueueSize) {
      logger.error("Queue is full. Can't add more tasks.");
      return;
    }

    // Create a new task object and insert it into the priority queue (MinHeap)
    const task: Task = {
      fn, // The task function
      priority: priority || this.nextPriority++, // Task priority (lower numbers have higher priority)
      retries, // Retry count
      maxRetries: retries, // Store max retries
      timeout, // Timeout for the task (optional)
      taskId, // Unique id for identification
    };

    this.queue.push(task); // Insert task into the priority queue
    logger.debug('Task enqueued', task);
    // If the queue is not paused, start processing tasks
    if (!this.isPaused) {
      this.run();
    }
  }

  /**
   * Main run function to execute tasks from the queue based on priority and concurrency limits.
   */
  async run() {
    // Prevent further execution if queue is paused or max concurrency is reached
    if (this.isPaused || this.runningCount >= this.concurrencyLimit || this.queue.isEmpty()) return;

    if (this.runTimeout) return; // Debounce to prevent multiple concurrent runs
    this.runTimeout = setTimeout(async () => {
      this.runTimeout = null;

      // Run tasks as long as concurrency limit allows and queue is not empty
      while (this.runningCount < this.concurrencyLimit && !this.queue.isEmpty()) {
        this.runningCount++;
        const task = this.queue.pop(); // Extract the highest-priority task
        logger.debug('Running task', task, this.queue);
        if (task) {
          this.triggerEvent('taskStart', task); // Emit task start event

          try {
            await this.executeTask(task); // Execute the task (with retries and timeout)
            this.triggerEvent('taskComplete', task); // Emit task complete event
          } catch {
            this.triggerEvent('taskError', task); // Emit task error event
          }
        }
        this.runningCount--; // Decrement the running count after task finishes
      }

      this.run(); // Continue processing the queue if tasks remain
    }, this.runDebounceTimeMs); // Debounce timeout to avoid frequent function calls
  }

  /**
   * Execute a task with retry support and optional timeout.
   *
   * @param {Task} task - The task to be executed.
   */
  private async executeTask(task: Task) {
    let attempt = 0;
    while (attempt <= task.maxRetries) {
      attempt++;
      try {
        if (task.timeout) {
          await this.runWithTimeout(task.fn, task.timeout); // Run task with timeout
        } else {
          await task.fn(); // Run task normally
        }
        break; // If task succeeds, break out of retry loop
      } catch (error) {
        if (attempt > task.maxRetries) throw error; // Throw error if max retries exceeded
      }
    }
  }

  /**
   * Run a task with timeout support.
   *
   * @param fn - The task function to be executed.
   * @param timeout - The timeout in milliseconds.
   */
  private runWithTimeout(fn: TaskOptions['fn'], timeout: number) {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => reject(new Error('Task timed out')), timeout); // Reject if timeout

      fn()
        .then(result => {
          clearTimeout(timer); // Clear timeout on success
          resolve(result);
        })
        .catch(error => {
          clearTimeout(timer); // Clear timeout on failure
          reject(error);
        });
    });
  }

  /**
   * Pause the queue, preventing any new tasks from being started.
   */
  pause(): void {
    this.isPaused = true;
    logger.debug('queue processing paused');
  }

  /**
   * Resume the queue, allowing tasks to be processed again.
   */
  resume(): void {
    if (this.isPaused) {
      this.isPaused = false;
      logger.debug('queue processing resumed');
      this.run();
    }
  }

  /**
   * Register an event listener for task events (e.g., taskStart, taskComplete, taskError).
   *
   * @param {string} eventName - The event name (e.g., 'taskStart', 'taskComplete', 'taskError').
   * @param {TaskEventCallback} callback - The callback function to trigger when the event occurs.
   */
  on(eventName: TaskEvent, callback: TaskEventCallback): void {
    if (!this.eventListeners[eventName]) {
      this.eventListeners[eventName] = [];
    }
    this.eventListeners[eventName].push(callback);
  }

  /**
   * Trigger an event to notify registered listeners.
   *
   * @param {string} eventName - The event name.
   * @param {Task} task - The task associated with the event.
   */
  private triggerEvent(eventName: TaskEvent, task: Task): void {
    if (this.eventListeners[eventName]) {
      this.eventListeners[eventName].forEach(callback => callback(task));
    }
  }

  /**
   * Get the taskId of the next task to be executed based on priority.
   *
   * This function leverages the `peekMin()` method to retrieve the task that will be executed next
   * (i.e., the task with the highest priority). If a task exists, it returns its `taskId`. If no tasks
   * are present, it returns null.
   *
   * @returns {string | null} - The `taskId` of the next task to be executed or null if the queue is empty.
   */
  getNextTaskId(): string | null {
    return this.queue.peek()?.taskId ?? null; // Peek at the task with the highest priority (min priority)
  }

  /**
   * Generate a unique task ID for each task.
   *
   * This function generates a unique `taskId` by combining the prefix `"task-"` with the current
   * timestamp. The use of the timestamp ensures that each task gets a unique identifier at the
   * moment of its creation.
   *
   * @returns {string} - A unique `taskId` for a task in the format "task-<timestamp>".
   */
  private generateTaskId(): string {
    return `task-${this.nextTaskId++}`;
  }

  isEmpty() {
    return this.queue.isEmpty();
  }

  size() {
    return this.queue.size;
  }
}
