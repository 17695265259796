// https://naspersclassifieds.atlassian.net/wiki/spaces/DATA/pages/57720210575/Ninja+Android#WebViews
import { UserProps } from '@/types/events';
import { omit } from '@/utils/object';

type AndroidWebview = typeof window & {
  NinjaAndroid?: {
    trackView: (name: string, extraData: string) => void;
    trackEvent: (name: string, extraData: string) => void;
  };
  LaquesisAndroid?: {
    getVariant: (name: string) => string;
    isFlagEnabled: (name: string) => boolean;
  };
};

const theWindow = window as AndroidWebview;

/**
 * Tracks a page event by forwarding the message to the native Android app
 */
export const trackPage = (name: string, extraData: UserProps = {}) => {
  const props = omit(extraData, 'trackPage');
  // Android expects params to be JSON-encoded strings
  return theWindow?.NinjaAndroid?.trackView(name, JSON.stringify(props));
};

/**
 * @params {string} name
 * @param {Record<string, unknown>} [extraData]
 */
export const trackEvent = (name: string, extraData: UserProps = {}) => {
  const props = omit(extraData, 'trackEvent');
  // Android expects params to be JSON-encoded strings
  return theWindow?.NinjaAndroid?.trackEvent(name, JSON.stringify(props));
};

export const trackEventDelayed = trackEvent;

// Laquesis
/**
 * @param {string} name
 */
export const getLaquesisVariant = (name: string) => theWindow?.LaquesisAndroid?.getVariant(name);

/**
 * @param {string} name
 */
export const isFeatureEnabled = (name: string) => theWindow?.LaquesisAndroid?.isFlagEnabled(name) ?? false;

export const flushDelayed = () => {};
