import { getNinjaConfig } from '@/config/ninja';
import { getLogger } from '@/logger';
import { Event } from '@/types/events';
import { loadScript } from '@/utils/browser';
import { makeMapping } from '@/utils/mapping';
import { BaseTracker } from './BaseTracker';
import { initGa } from './init-snippets/initGa';

const logger = getLogger('GaTracker');

export class GaTracker extends BaseTracker {
  customDataLayer: string;

  constructor() {
    super();

    this.isInitPushed = false;
    this.trackerName = 'ga';
    this.scriptTagId = 'ga-script-tag';
    this.scriptUrl = 'https://www.googletagmanager.com/gtag/js';
    this.customDataLayer = 'gtagDataLayer';

    this.listeners = {
      trackEvent: e => this.initAndExecute(() => this.trackEvent(e)),
      trackPage: e => this.initAndExecute(() => this.trackPage(e)),
      trackLinkEvent: e => this.initAndExecute(() => this.trackLinkEvent(e)),
    };
  }

  async init() {
    if (!this.hasRequiredConsent()) {
      this.cleanup();
      return this;
    }

    const token = this.getToken();

    if (!token) {
      return Promise.reject('No token found for GA tracker');
    }

    // this.isInitialized = true;

    const oldTag = document.getElementById(this.scriptTagId as string);
    this.isInitPushed = !!oldTag;

    if (!this.isInitPushed) {
      this.isInitPushed = true;
      (window as any)[`ga-disable-${token}`] = false;
      initGa(token as string, this.customDataLayer);

      try {
        const url = this.scriptUrl + '?l=' + this.customDataLayer;
        await loadScript(url, this.scriptTagId as string);
        this.isInitialized = true;
        logger.debug('GA tracker initialized');
      } catch (err) {
        logger.error('GA tracker initialization error', err);
        this.isInitialized = false;
        this.cleanup();
        throw err;
      }
    }

    return this;
  }

  async trackEvent(e: Event) {
    if (!this.hasRequiredConsent()) {
      this.cleanup();
      logger.debug('No consent from the user. Event tracking aborted', e);
      return;
    }
    if (!this.isInitialized) {
      try {
        await this.init();
      } catch (err) {
        logger.error('Failed to initialize GA tracker. Event tracking aborted', err);
        return;
      }
    }

    const mappedEvent = makeMapping(this.trackerName, 'trackEvent', this.convertString(e.value));
    if (!mappedEvent) {
      logger.debug('Values not found in the mapping. Event tracking aborted', e.value);
      return;
    }

    const category = mappedEvent.value.substring(0, mappedEvent.value.indexOf('_'));
    try {
      window.gtag('event', mappedEvent.value, {
        debug_mode: getNinjaConfig().debug,
        eventLabel: mappedEvent.value,
        eventCategory: category || 'default',
        ...this.getTrackParams(e),
      });

      logger.debug('GA event tracked', e.actionType, e.value, e.props);
    } catch (error) {
      logger.error('Failed to track GA event', error, e.actionType, e.value, e.props);
    }
  }

  async trackPage(e: Event) {
    if (!this.hasRequiredConsent()) {
      this.cleanup();
      logger.debug('No consent from the user. Page tracking aborted', e);
      return;
    }
    if (!this.isInitialized) {
      try {
        await this.init();
      } catch (err) {
        logger.error('Failed to initialize GA tracker. Page tracking aborted', err);
        return;
      }
    }
    if (!e.value) {
      logger.debug('Page name missing. Page tracking aborted');
      return;
    }

    const mappedPage = makeMapping(this.trackerName, 'trackPage', e.value);
    if (!mappedPage) {
      logger.debug('Values not found in the mapping. Page tracking aborted', e.value);
      return;
    }
    const pageName = this.convertPageNameString(decodeURIComponent(mappedPage.value));
    try {
      window.gtag('event', 'page_view', {
        page_title: pageName,
        page_path: window.location.pathname,
        ...this.getTrackParams(e),
      });
      logger.debug('GA page tracked', e.actionType, pageName, e.props);
    } catch (error) {
      logger.error('Failed to track GA page', error, e.actionType, pageName, e.props);
    }
  }

  // Disable SDK
  cleanup() {
    super.cleanup();
    (window as any)[`ga-disable-${this.getToken()}`] = true;
  }

  convertString(text: string) {
    if (typeof text === 'string') {
      return text.toLowerCase().replace(/\s+|[-/]/g, '_');
    }
    return text;
  }

  convertPageNameString(text: string) {
    if (typeof text === 'string') {
      return text.replace(/[^a-zA-Z0-9/]/g, '_');
    }
    return text;
  }
}
