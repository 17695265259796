import { ONAP_SESSION_ACTIVE_DURATION } from '@/const/general';
import { readLQOnap, readOnap, writeOnap } from '@/cookies/ninja';
import { getLogger } from '@/logger';
import { NinjaSession, SessionParams } from '@/types/params';
import { state } from './state';

const logger = getLogger('sessionManager');

/**
 * Make sure `onap` cookie is set and update the session expiry time.
 */
export function startSession() {
  logger.debug('Starting session');
  let session = readOnap();

  // if own session doesn't exist, check if there is a server lqonap cookie
  if (!session) {
    const lqsession = readLQOnap();

    if (lqsession) {
      session = generateNewNinjaSession(lqsession);
      state.cookieFromLq = true;
      logger.debug('Using LQONAP cookie as session_long');
    } else {
      // No session found, generate new session
      session = generateNewNinjaSession();
      logger.debug('Generated new session', session);
    }
  }

  state.currentSession = session;
  writeOnap(state.currentSession);

  return session;
}

export function validateAndUpdateSession(session: NinjaSession) {
  if (isSessionExpired(session)) {
    // Session expired, generate new session and restart counter
    session.session = generateSessionId();
    session.sessionCountLong += 1;
    session.sessionCount = 1;

    logger.debug('Session expired, generating new session', session);
  } else {
    session.sessionCount += 1;
  }
  setSessionActive(session);

  return session;
}

export function generateSessionId() {
  return `${Date.now().toString(16)}x${Math.ceil(Math.random() * 0xffffffff).toString(16)}`;
}

/**
 * Generates new ninja session.
 * `sessionLong` and `session` are the same. Session expires after 30 minutes of inactivity.
 */
export function generateNewNinjaSession(sessionLong = generateSessionId()) {
  const session: NinjaSession = {
    sessionLong,
    session: sessionLong,
    sessionCountLong: 1,
    sessionCount: 0,
    noCookie: !navigator.cookieEnabled,
    expires: 0,
  };

  setSessionActive(session);

  return session;
}

/**
 * Marks the session as active and sets the `expires` field to 30 minutes from now.
 */
export function setSessionActive(session: NinjaSession) {
  session.expires = Date.now() + ONAP_SESSION_ACTIVE_DURATION;
}

export function isSessionExpired(session: NinjaSession) {
  return session.expires <= Date.now();
}

/**
 * Manage the session cookie and update counters
 */
export function getSessionParams() {
  state.currentSession = validateAndUpdateSession(state.currentSession!);
  writeOnap(state.currentSession);

  const sessionParams: SessionParams = {
    sessionLong: state.currentSession.sessionLong,
    session: state.currentSession.session,
    sessionCountLong: state.currentSession.sessionCountLong,
    sessionCount: state.currentSession.sessionCount,
  };

  if (!navigator.cookieEnabled) {
    sessionParams.noCookie = true;
  }

  return sessionParams;
}
