import { NinjaSession } from '@/types/params';
import { SurveySetup } from '@/types/surveys';

type State = {
  /**
   * Experiments and their variants assigned to the user
   */
  activeExps: Map<string, string>;
  /**
   * Flags assigned to the user
   */
  activeFlags: Set<string>;
  /**
   * Surveys assigned to the user
   */
  activeSurveys: Array<SurveySetup>;
  /**
   * Indicates if lqonap cookie was set with a server-generated session_long
   */
  cookieFromLq: boolean;
  /**
   * Current platform value, as string, use @see {Platform}
   */
  currentPlatform: string;

  /**
   * Current session id
   */
  currentSession?: NinjaSession;
  /**
   * Current session long identifier
   */
  // currentSessionLong: string;
  /**
   * First page event name tracked by Hydra. Web-vitals events are linked to this page.
   */
  firstTrackedPageEvent: string;
  /**
   * Indicates if laquesis definitions need to be refreshed
   */
  isLQRefreshNeeded: boolean;
  /**
   * Indicates if a survey has been triggered and is visible to the user.
   * Blocks further survey triggers until the current survey is closed.
   */
  isSurveyTriggered: boolean;
  /**
   * Indicates playground usage and stops laquesis definitions from being refreshed
   */
  playgroundMode: boolean;
  /**
   * A mapping of survey ids to the events that should trigger them
   */
  surveyTriggerMap: Record<string, number[]>;
  /**
   * Enables the logged-in user experiments
   */
  userId?: string | number;

  /**
   * The survey which has been triggered and is visible to the user.
   * Blocks further survey triggers until the current survey is closed.
   */
  triggeredSurvey?: Required<SurveySetup>;
};

// Shared configs (not coming from `window.configTracking`)
export const state: State = {
  activeExps: new Map<string, string>(),
  activeFlags: new Set<string>(),
  activeSurveys: [],
  cookieFromLq: false,
  currentPlatform: '',
  currentSession: undefined,
  // currentSessionLong: '',
  firstTrackedPageEvent: '',
  isLQRefreshNeeded: false,
  isSurveyTriggered: false,
  playgroundMode: false,
  surveyTriggerMap: {},
  userId: undefined,
};
