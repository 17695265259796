import { MetricType, ReportOpts, onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals';
import { GENERAL_EVENTS } from '@/const/events';
import { ninjaBus } from '@/eventbus';
import { getLogger } from '@/logger';

const logger = getLogger('web-vitals');

/**
 * Mark when user has interacted with the page
 */
export let hasUserInteractedWithPage = false;
let isLoaded = false;

/**
 * Enabled metrics to track
 */
const watchedMetrics = [onCLS, onFCP, onFID, onINP, onLCP, onTTFB];

export async function loadWebVitals() {
  if (isWebVitalsEnabled() && !isLoaded) {
    setMetricWatchers();
    isLoaded = true;
  }
}

/**
 * Perform the actual tracking of the metric
 */
export function trackWebVitalsMetric(metric: MetricType) {
  ninjaBus.emit(GENERAL_EVENTS.TRACK_WEB_VITAL, metric.name, { metric });
}

export function setMetricWatchers() {
  logger.debug('Setting web-vital listeners', watchedMetrics);
  for (const method of watchedMetrics) {
    method(trackWebVitalsMetric, { reportAllChanges: false } satisfies ReportOpts);
  }
}

export function isWebVitalsEnabled() {
  return !!window.performance && !!window.PerformanceObserver;
}

/**
 * Make sure all browsers mark interaction stop, even if web-vitals is not supported
 * @see https://github.com/GoogleChrome/web-vitals/blob/a948d3cde1770eabb8a215072cfd9713e940c4d9/src/onLCP.ts#L99
 */
function onInteraction() {
  hasUserInteractedWithPage = true;
  window.removeEventListener('click', onInteraction, true);
  window.removeEventListener('keydown', onInteraction, true);
}

window.addEventListener('click', onInteraction, true);
window.addEventListener('keydown', onInteraction, true);
