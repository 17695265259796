import { getQueryParams } from './browser';
import { convertToLatin } from './string';

const SEPARATOR = 'NINJA_SEPARATOR';
const SEPARATOR_REGEX = new RegExp(SEPARATOR, 'g');
const INVITE = 'invite';
const UTM_SOURCE = 'utm_source';
const UTM_PARAMS = ['utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_id'];
const ITM_SOURCE = 'itm_source';
const ITM_PARAMS = ['itm_medium', 'itm_campaign', 'itm_term', 'itm_content', 'itm_id'];

/**
 * Internal function - Collect the invite param from the URL
 */
export function getInvite() {
  if (!window.location.search) {
    return '';
  }

  const query = getQueryParams();
  let params: string[] = [];
  let source = '';

  // Try with invite
  if (query.has(INVITE)) {
    source = query.get(INVITE)!;
    params = parseInvite(source);
  } else {
    // Try with utm
    if (query.has(UTM_SOURCE)) {
      params = getCampaignParams(query, UTM_PARAMS);
      source = query.get(UTM_SOURCE)!;
    }
  }

  if (source) {
    const left = convertToLatin(decodeURIComponent(source)).replace(/\s/g, '').replace(SEPARATOR_REGEX, '-');
    const right = convertToLatin(decodeURIComponent(params.join('--')))
      .replace(/\s/g, '')
      .replace(SEPARATOR_REGEX, '-');

    return `${left}_${right}`;
  }

  return '';
}

/**
 * ITM is different from UTM. It needs to be separated and is tracked with different property
 */
export function getItmInvite() {
  const query = getQueryParams();

  if (!query.has(ITM_SOURCE)) {
    return '';
  }

  const source = query.get(ITM_SOURCE)!;
  const params = getCampaignParams(query, ITM_PARAMS);

  if (params.length > 0) {
    const left = convertToLatin(decodeURIComponent(source)).replace(/\s/g, '').replace(SEPARATOR_REGEX, '-');
    const right = convertToLatin(decodeURIComponent(params.join('--')))
      .replace(/\s/g, '')
      .replace(SEPARATOR_REGEX, '-');

    return `${left}_${right}`;
  }

  return '';
}

/**
 * Internal function - parse invite parameters
 */
export function parseInvite(invite: string) {
  const inviteParams = invite.match(/^\s*([a-zA-Z0-9-]{1,64})(?:_([a-zA-Z0-9%-]{0,128})).*/);

  if (inviteParams && inviteParams.length > 0) {
    return [inviteParams[1], inviteParams[2]];
  }

  return [];
}

/**
 * Parse campaign params
 */
export function getCampaignParams(query: URLSearchParams, params: string[]) {
  return params.reduce<string[]>((acc, param) => {
    if (query.has(param)) {
      return [...acc, sanitizeCampaignParam(query.get(param)!, SEPARATOR)];
    }
    return acc;
  }, []);
}

/**
 * Clear different separators form the campaign string and replace them with unified separator.
 * Later, it makes composing the final string easier.
 */
export function sanitizeCampaignParam(param: string, separator = SEPARATOR) {
  return param.replace(/[-_.]/g, separator);
}
