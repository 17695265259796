import { TEST_DIVIDER, VALUE_DIVIDER, VARIANT_DIVIDER } from '@/const/laquesis';

/**
 * Parse laquesis cookie value and call a callback
 * This can't be a Promise because it is used in react-laquesis
 */
export function parseLaquesisCookie(cookie = '', callback: (name: string, variant: string) => void = () => {}) {
  // Fix for some servers that quotes the cookie when contains @ :: Use value without quotes
  const cookieValue = cookie?.replace(/^"(.+)"$/, '$1') ?? '';
  const activeTests = new Map<string, string>();

  if (cookieValue) {
    for (const value of cookieValue.split(TEST_DIVIDER)) {
      if (!value) {
        continue;
      }
      const nameValue = value.split(VARIANT_DIVIDER);

      if (nameValue.length === 2) {
        const valueFlag = nameValue[1].split(VALUE_DIVIDER);
        if (valueFlag.length === 2) {
          // Read the set-cookie from the server
          if (valueFlag[1] === 't') {
            if (typeof callback === 'function') {
              callback(nameValue[0], valueFlag[0]);
            }

            if (!activeTests.has(nameValue[0])) {
              activeTests.set(nameValue[0], valueFlag[0]);
            }
          }
        } else {
          // Read the normal cookie
          if (!activeTests.has(nameValue[0])) {
            activeTests.set(nameValue[0], valueFlag[0]);
          }
        }
      }
    }
  }

  return activeTests;
}

/**
 * parse the flags cookie
 */
export function parseLaquesisFfCookie(cookieValue = '') {
  const activeFlags = new Set<string>();

  if (cookieValue) {
    for (const flag of cookieValue.split(TEST_DIVIDER)) {
      if (flag && !(flag in activeFlags)) {
        activeFlags.add(flag);
      }
    }
  }

  return activeFlags;
}
