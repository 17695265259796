import { getLogger } from '@/logger';
import { NinjaSession } from '@/types/params';
import { cookieStorage } from '../cookieStorage';
import { getCookieExpirationDate } from '../getCookieExpirationDate';
import { getCookieName } from '../getCookieName';

const logger = getLogger('cookies/ninja/onap');
const COOKIE_NAME = getCookieName('onap');
const COOKIE_NAME_LQONAP = getCookieName('lqonap');

export function normalizeTimestamp(ts: string = '') {
  const expiration = ts.slice(0, 14);
  let expires = Date.now();

  // correct milli format
  if (expiration.length === 13) {
    expires = parseInt(expiration);
  } else if (expiration.length == 10) {
    expires = parseInt(expiration) * 1000;
  }
  return expires;
}

export function readLQOnap() {
  return cookieStorage.get(COOKIE_NAME_LQONAP);
}

export function readOnap() {
  let session: NinjaSession | undefined = undefined;
  const value = cookieStorage.get(COOKIE_NAME);
  const sessionValues = value?.match(/([a-z0-9]+)-([0-9]+)-([a-z0-9]+)-([0-9]+)-([0-9]+)-?(.*)?/);

  if (sessionValues) {
    logger.debug('Reading ONAP cookie', sessionValues);

    session = {
      sessionLong: sessionValues[1],
      sessionCountLong: parseInt(sessionValues[2], 10),
      session: sessionValues[3],
      sessionCount: parseInt(sessionValues[4], 10),
      expires: normalizeTimestamp(sessionValues[5]),
      extra: sessionValues[6],
    } as NinjaSession;
  }

  return session;
}

export function writeOnap(def: NinjaSession) {
  // Order is important!
  const arrayValue = [def.sessionLong, def.sessionCountLong, def.session, def.sessionCount, def.expires];

  if (def.extra) {
    arrayValue.push(def.extra);
  }

  cookieStorage.set(COOKIE_NAME, arrayValue.join('-'), {
    expires: getCookieExpirationDate(1, 'year'),
  });

  logger.debug('Writing ONAP cookie', arrayValue);
}
