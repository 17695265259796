import { getNinjaConfig } from '@/config/ninja';
import { TEST_DIVIDER, VALUE_DIVIDER } from '@/const/laquesis';
import { getLogger } from '@/logger';
import { LQDefinition } from '@/types/laquesis';
import { cookieStorage } from '../cookieStorage';
import { getCookieExpirationDate } from '../getCookieExpirationDate';
import { getCookieName } from '../getCookieName';

const logger = getLogger('cookies/lqstatus');

// Handles laquesis definitiona reading and writing (lqstatus cookie)
const COOKIE_NAME_LQ = getCookieName('lqstatus');

export function readLQDefinition() {
  const result = cookieStorage.get(COOKIE_NAME_LQ);
  logger.debug('Reading cookie', result);
  return result;
}

export function writeLQDefinition(def: LQDefinition) {
  // Order is important!
  const arrayValue = [
    def.nextRefresh,
    def.currentSession || '',
    def.trackedExperiments,
    def.nextSurveyShow || '',
    def.showedSurveys ? def.showedSurveys.join(TEST_DIVIDER) : '',
    def.isDefinitionsForUserId ? '1' : '0',
  ];

  logger.debug('Writing cookie', arrayValue);
  cookieStorage.set(COOKIE_NAME_LQ, arrayValue.join(VALUE_DIVIDER), {
    expires: getCookieExpirationDate(1, 'year'),
    path: '/',
    domain: getNinjaConfig().cookieDomain,
  });
}

/**
 * Validate and get string consistency
 */
export function getLQDefinition() {
  // module.lqDefinition[0] == Timestamp => Next Refresh
  // module.lqDefinition[1] == 7832af83x1123 => Current session
  // module.lqDefinition[2] == exp-1#exp-2 => Tracked experiments in this session
  // module.lqDefinition[3] == Timestamp => Next survey show
  // module.lqDefinition[4] == survey_id1#survey_id2 => Showed surveys
  // module.lqDefinition[5] == 1|0 => 0 = sl, 1 = ui
  const defString = readLQDefinition();
  if (!defString) {
    return {
      nextRefresh: -1,
      currentSession: '',
      trackedExperiments: '',
      nextSurveyShow: 0,
      showedSurveys: [],
      isDefinitionsForUserId: false,
    } as LQDefinition;
  }

  return parseLQDefinition(defString);
}

export function parseLQDefinition(str = '') {
  const parts = str.split(VALUE_DIVIDER);
  const def: Record<string, any> = {
    nextRefresh: parts[0],
    currentSession: parts[1] || '',
    trackedExperiments: parts[2],
    nextSurveyShow: parts[3],
    showedSurveys: parts[4],
    isDefinitionsForUserId: parts[5] === '1',
  };

  // validate each part
  if (/\d{10}/.test(def.nextRefresh)) {
    def.nextRefresh = parseInt(def.nextRefresh, 10);
  } else {
    def.nextRefresh = 0;
  }

  // JIRA_REGEX = /^[A-Z]{1}[A-Z\d]{0,9}-\d+$/ - we use lowecase here
  if (!/^([a-z]{1}[a-z\d]{0,9}-\d+#?)+$/.test(def.trackedExperiments)) {
    def.trackedExperiments = '';
  }

  if (/\d{10}/.test(def.nextSurveyShow)) {
    def.nextSurveyShow = parseInt(def.nextSurveyShow, 10);
  } else {
    def.nextSurveyShow = 0;
  }

  if (/^(\d+#?)*[^#]$/.test(def.showedSurveys)) {
    def.showedSurveys = def.showedSurveys.split(TEST_DIVIDER);
  } else {
    def.showedSurveys = [];
  }

  logger.debug('Parsing cookie', def);
  return def as LQDefinition;
}
