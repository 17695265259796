/**
 * Clean a given cookie from all possible domains and paths
 * @param cName - the cookie name
 * @param [allowedDomains=[]] - list of domains to skip cleanup
 * @param [allowedPaths=[]] - list of paths to skip cleanup
 */
export function cleanCookie(cName: string, allowedDomains: string[] = [], allowedPaths: string[] = []) {
  const d = window.location.hostname.split('.');
  const cookiesToRemove = [encodeURIComponent(cName) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/'];
  let cookieBase: string;

  // Valid domains require at least one dot
  while (d.length > 1) {
    const domain = d.join('.');
    let domainsWithPrefix: string[] = [];

    if (Array.isArray(allowedDomains) && !allowedDomains.includes(domain)) {
      domainsWithPrefix = [domain];
    }

    if (!domain.match(/^www\./)) {
      domainsWithPrefix.push('www.' + domain);
      domainsWithPrefix.push('.www.' + domain);
    }

    for (const prefix of domainsWithPrefix) {
      cookieBase = encodeURIComponent(cName) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + prefix + ' ;path=';

      // clean cookie at root path
      if (!cookiesToRemove.includes(cookieBase + '/')) {
        cookiesToRemove.push(cookieBase + '/');
      }

      const p = location.pathname.split('/');
      // clean cookie at all possible sub-paths
      while (p.length > 0) {
        const cPath = p.join('/');
        if (cPath !== '/' && cPath !== '' && !allowedPaths.includes(cPath)) {
          cookiesToRemove.push(cookieBase + cPath);
        }

        p.pop();
      }
    }

    d.shift();
  }

  for (const cookie of cookiesToRemove) {
    document.cookie = cookie;
  }

  return cookiesToRemove;
}
