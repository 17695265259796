import { getNinjaConfig } from '@/config/ninja';
import { getLogger } from '@/logger';
import { UserEvent, UserProps } from '@/types/events';
import { isNinjaEvent } from '@/utils/event';

const _logger = getLogger('trackers/params/getCustomParams');

/**
 * Will join the user properties with the previous events' properties.
 * If `disablePropertyPropagation=false`, it will just return the user properties.
 */
export function getCustomParams(userProps: UserProps, events: UserEvent[], disablePropertyPropagation = false) {
  // if propagation is disabled, do not check any previous events
  const eventsToCheck = disablePropertyPropagation ? [] : events;
  const customParams: UserProps = {};

  // gather the latest value for each previous param
  for (const event of eventsToCheck) {
    // do not include non-processed events in the prop inheritance chain
    if (event.processed !== true || !isNinjaEvent(event)) {
      continue;
    }

    for (const [subKey, value] of Object.entries(event)) {
      if (!getNinjaConfig().specialNames.includes(subKey)) {
        customParams[subKey] = value;
      }
    }
  }

  // merge previous values with current params
  return {
    ...customParams,
    ...userProps,
  };
}
