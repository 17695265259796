/**
 * This file controls which trackers will be included in the build.
 * Trackers are provided as an env variables
 * include/exlude trackers based on those env variables.
 */
import { getNinjaConfig } from '@/config/ninja';
import { getRegionConfig } from '@/config/region';
import { TRACKER_ABBREVIATIONS } from '@/const/general';
import { Laquesis } from '@/laquesis/init';
import { getLogger } from '@/logger';
import { Surveys } from '@/surveys/Surveys';
import { BaseTracker } from './BaseTracker';
import { BrazeTracker } from './BrazeTracker';
import { FacebookTracker } from './FacebookTracker';
import { GaTracker } from './GaTracker';
import { GtmTracker } from './GtmTracker';
import { HydraTracker } from './HydraTracker';

const logger = getLogger('registerTrackers');

// TODO: add tests for the file
export function isLaquesisEnabled() {
  const regionConfig = getRegionConfig();
  const pluginList: string[] = regionConfig?.plugins ?? [];
  const siteConfig = regionConfig?.custom?.[getNinjaConfig().siteUrl];

  if (siteConfig?.plugins) {
    pluginList.push(...siteConfig.plugins);
  }

  return pluginList.includes(TRACKER_ABBREVIATIONS.laquesis);
}

export function getActiveTrackers() {
  const trackers: BaseTracker[] = [];

  if (!getNinjaConfig().isNative) {
    if (import.meta.env.__FACEBOOK__) {
      trackers.push(new FacebookTracker());
    }
    if (import.meta.env.__GTM__) {
      trackers.push(new GtmTracker());
    }

    if (import.meta.env.__GA__) {
      trackers.push(new GaTracker());
    }

    if (import.meta.env.__BRAZE__) {
      trackers.push(new BrazeTracker());
    }
  }

  return trackers;
}

/**
 * Init trackers and returns a function for all trackers that are delayed (laquesis).
 * The delayed trackers need to be initialized after the dataLayer has been processed.
 */
export async function registerTrackers() {
  const trackers = getActiveTrackers();
  const hydra = new HydraTracker();
  const delayedInit: () => Promise<void> = () => Promise.resolve();

  await hydra.setupListeners();
  await hydra.init();

  if (isLaquesisEnabled()) {
    const surveys = new Surveys();
    const laquesis = new Laquesis();

    await surveys.setupListeners(); // init is inside
    await laquesis.setupListeners();
    await laquesis.init();
  }

  for (const tracker of trackers) {
    try {
      logger.debug('Processing', tracker.trackerName);
      await tracker.setupListeners();
    } catch {
      // pass
    }
  }

  logger.debug('Trackers initialized');

  return delayedInit;
}
