import { getLogger } from '@/logger';
import { Event, EventHandler, EventMeta, IEventBus } from '@/types/events';

const logger = getLogger('EventBus');

/**
 * It's a class for managing events.
 * It can be extended to provide event functionality for other classes or object.
 */
export class EventBus implements IEventBus {
  /**
   * the all event handlers are added.
   * it's a Map data structure(key-value), the key is event type, and the value is event handler.
   */
  private _eventHandlers: Record<string, EventHandler[] | undefined> = {};

  /**
   * event type validator.
   *
   * @param {string} type event type
   */
  private isValidType(type: string) {
    return typeof type === 'string';
  }

  /**
   * event handler validator.
   *
   * @param {EventHandler} handler event handler
   */
  private isValidHandler(handler: EventHandler) {
    return typeof handler === 'function';
  }

  /**
   * listen on a new event by type and handler.
   * if listen on, the true is returned, otherwise the false.
   * The handler will not be listen if it is a duplicate.
   *
   * @param {string} type event type, it must be a unique string.
   * @param {EventHandler} handler event handler, when if the same handler is passed, listen it by only once.
   * @example
   *  const emitter = new EventEmitter();
   *  emitter.on('change:name', evt => {
   *    console.log(evt);
   *  });
   */
  on(type: string, handler: EventHandler) {
    if (!type || !handler) return false;

    if (!this.isValidType(type)) return false;
    if (!this.isValidHandler(handler)) return false;

    let handlers = this._eventHandlers[type];
    if (!handlers) handlers = this._eventHandlers[type] = [];

    // when the same handler is passed, listen it by only once.
    if (handlers.indexOf(handler) >= 0) return false;

    handlers.push(handler);

    logger.debug('New listener added:', type, handler);

    return true;
  }

  /**
   * listen off an event by type and handler.
   * or listen off events by type, when if only type argument is passed.
   * or listen off all events, when if no arguments are passed.
   *
   * @param {string} [type] event type
   * @param {EventHandler} [handler] event handler
   * @example
   *  const emitter = new EventEmitter();
   *  // listen off the specified event
   *  emitter.off('change:name', evt => {
   *    console.log(evt);
   *  });
   *  // listen off events by type
   *  emitter.off('change:name');
   *  // listen off all events
   *  emitter.off();
   */
  off(type?: string, handler?: EventHandler) {
    // listen off all events, when if no arguments are passed.
    // it does samething as `offAll` method.
    if (!type) return this.offAll();

    // listen off events by type, when if only type argument is passed.
    if (!handler) {
      this._eventHandlers[type] = [];
      return;
    }

    if (!this.isValidType(type)) return;
    if (!this.isValidHandler(handler)) return;

    const handlers = this._eventHandlers[type];
    if (!handlers || !handlers.length) return;

    // otherwise, listen off the specified event.
    for (let i = 0; i < handlers.length; i++) {
      const fn = handlers[i];
      if (fn === handler) {
        handlers.splice(i, 1);
        logger.debug('Listener removed:', type, handler);
        break;
      }
    }
  }

  /**
   * listen off all events, that means every event will be emptied.
   * @example
   *  const emitter = new EventEmitter();
   *  emitter.offAll();
   */
  offAll() {
    this._eventHandlers = {};
    logger.debug('All listeners removed');
  }

  /**
   * fire the specified event, and you can to pass a data.
   * When fired, every handler attached to that event will be executed.
   */
  emit<T = any>(type: string, value: string, data?: T, meta?: EventMeta) {
    if (!type || !this.isValidType(type)) return;

    const handlers = this._eventHandlers[type];
    if (!handlers || !handlers.length) return;

    const event = this.createEvent(type, value, data, meta);

    logger.debug(`Event emit: ${type} = ${value}`, data);

    for (const handler of handlers) {
      if (!this.isValidHandler(handler)) continue;

      handler(event);
    }

    return event;
  }

  /**
   * check whether the specified event has been listen on.
   * or check whether the events by type has been listen on, when if only `type` argument is passed.
   *
   * @param {string} type event type
   * @param {EventHandler} [handler] event handler, optional
   * @example
   *  const emitter = new EventEmitter();
   *  const result = emitter.has('change:name');
   */
  has(type: string, handler?: EventHandler) {
    if (!type || !this.isValidType(type)) return false;

    const handlers = this._eventHandlers[type];
    // if there are no any events, return false.
    if (!handlers || !handlers.length) return false;

    // at lest one event, and no pass `handler` argument, then return true.
    if (!handler || !this.isValidHandler(handler)) return true;

    // otherwise, need to traverse the handlers.
    return handlers.indexOf(handler) >= 0;
  }

  /**
   * get the handlers for the specified event type.
   *
   * @param {string} type event type
   * @example
   *  const emitter = new EventEmitter();
   *  const handlers = emitter.getHandlers('change:name');
   *  console.log(handlers);
   */
  getHandlers(type: string) {
    if (!type || !this.isValidType(type)) return [];
    return this._eventHandlers[type] || [];
  }

  /**
   * create event object.
   */
  createEvent(actionType: string, value: string, props?: any, meta?: any) {
    const event: Event = {
      actionType,
      value,
      props,
      meta,
      t: Date.now(),
    };
    logger.debug('Event created', actionType, value, props, meta, event);

    return event;
  }
}
