import { getNinjaConfig } from '@/config/ninja';

export enum LogLevel {
  Debug = 0,
  Info = 1,
  Warn = 2,
  Error = 3,
}

export interface ILogger {
  debug: (...args: unknown[]) => void;
  info: (...args: unknown[]) => void;
  warn: (...args: unknown[]) => void;
  error: (...args: unknown[]) => void;
}

const globalMinLevel = getNinjaConfig().debug ? LogLevel.Debug : LogLevel.Warn;

export const PREFIX = '[Ninja]';

export function getLogger(module: string, minLevel: LogLevel = globalMinLevel): ILogger {
  const prefixStyles = 'color: magenta';
  const moduleStyles = 'font-weight: bold';
  const prefix = (txt: string, style: string) => [`%c${PREFIX} %c${txt} %c${module}:`, prefixStyles, style, moduleStyles];
  const logger: ILogger = {
    debug(...args) {
      if (LogLevel.Debug >= minLevel) {
        console.debug.apply(console, [...prefix(`DEBUG`, 'color: cyan'), ...args]);
      }
    },
    info(...args) {
      if (LogLevel.Info >= minLevel) {
        console.info.apply(console, [...prefix(`DEBUG`, 'color: lightblue'), ...args]);
      }
    },
    warn(...args) {
      if (LogLevel.Warn >= minLevel) {
        console.warn.apply(console, [...prefix(`DEBUG`, 'color: orange'), ...args]);
      }
    },
    error(...args) {
      if (LogLevel.Error >= minLevel) {
        console.error.apply(console, [...prefix(`DEBUG`, 'color: red'), ...args]);
      }
    },
  };

  return logger;
}
