import { CUSTOM_EVENTS, INTERNAL_EVENTS } from '@/const/events';
import { GENERAL_EVENTS } from '@/const/events';
import { ninjaBus } from '@/eventbus';
import { getLogger } from '@/logger';
import { state } from '@/ninja/state';
import { BaseTracker } from '@/trackers/BaseTracker';
import { SurveySetup } from '@/types/surveys';
import { isStorageAvailable } from '@/utils/browser';
import { triggerCustomEvent } from '@/utils/event';
import { checkSurveysForTrigger, isSurveyAvailable } from './conditions';
import { triggerSurvey } from './display';
import { assignQASurvey, assignSurveys, clearSurveyStorage, initSurveyStorage } from './storage';

const logger = getLogger('Surveys');

export class Surveys extends BaseTracker {
  constructor() {
    super();
    this.trackerName = 'surveys';
    this.listeners = {
      [INTERNAL_EVENTS.INIT_SURVEYS_STORAGE]: () => initSurveyStorage(),
      [INTERNAL_EVENTS.ASSIGN_QA_SURVEY]: e => assignQASurvey(e.props.surveyId as number),
      [INTERNAL_EVENTS.ASSIGN_SURVEYS]: e => assignSurveys(e.props.surveys as SurveySetup[]),
      [INTERNAL_EVENTS.SETUP_SURVEYS_WINDOW]: () => setupWindow(),
      [INTERNAL_EVENTS.CHECK_SURVEYS_FOR_TRIGGER]: e => checkSurveysForTrigger(e),
      [INTERNAL_EVENTS.TRIGGER_SURVEY]: e => triggerSurvey(e.props.surveyId as number, e.props.link as string | undefined),
      [INTERNAL_EVENTS.CLOSE_SURVEY]: () => {
        triggerCustomEvent(CUSTOM_EVENTS.LAQUESIS_SURVEY_CLOSE, { id: state.triggeredSurvey?.id });
        state.triggeredSurvey = undefined;
      },
      [INTERNAL_EVENTS.TRACK_SURVEY_TRIGGER]: e => ninjaBus.emit(GENERAL_EVENTS.TRACK_SURVEY, e.value, e.props),
    };
  }

  async init() {
    if (!isStorageAvailable()) {
      this.cleanup();
      clearSurveyStorage();
      return;
    }
    this.isInitialized = true;
    logger.debug('surveys initialized');
    return this;
  }

  async setupListeners() {
    await super.setupListeners();
    if (!this.isInitialized) {
      await this.init();
    }

    return this;
  }

  async trackEvent() {}
  async trackPage() {}
}

export function setupWindow() {
  window.laquesisShowSurvey = function (id) {
    if (!isSurveyAvailable(id)) {
      return Promise.reject('Survey not available, already shown or in lockout period');
    }
    state.playgroundMode = false;
    logger.debug('Survey triggered manually using window.laquesisShowSurvey', id);
    ninjaBus.emit(INTERNAL_EVENTS.TRIGGER_SURVEY, '', { surveyId: id });
    ninjaBus.emit(GENERAL_EVENTS.TRACK_FEATURE_USAGE, '', { message: 'surveys/function trigger' });
  };
  window.isSurveyAvailable = function (id) {
    return isSurveyAvailable(id);
  };
  window.laquesisShowSurveyByLink = function (link) {
    state.playgroundMode = true;
    ninjaBus.emit(INTERNAL_EVENTS.TRIGGER_SURVEY, '', { surveyId: 0, link });
  };
}
