/**
 * Simple wrapper around `fetch`
 */
async function makeRequest(input: string | URL | Request, init?: RequestInit) {
  const response = await fetch(input, init);

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response;
}

/**
 * Use `window.fetch` to make a request to the given path and return the JSON response.
 */
export async function fetchJSON<T>(path: string): Promise<T> {
  const response = await makeRequest(path);
  return (await response.json()) as T;
}

/**
 * Use `window.fetch` to make a request to the given path and return the text response.
 */
export async function fetchText(path: string) {
  const response = await makeRequest(path);

  return await response.text();
}
