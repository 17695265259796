import { getNinjaConfig } from '@/config/ninja';
import { TIMESTAMP_PROP } from '@/const/general';
import { ninjaBus } from '@/eventbus';
import { getLogger } from '@/logger';
import { getCustomParams } from '@/trackers/params';
import { UserEvent, UserProps } from '@/types/events';
import { HandlerFn } from '@/types/native';
import { getEventMeta, isLaquesisEvent, isNinjaEvent } from '@/utils/event';
import { omit } from '@/utils/object';
import { nativeHandlerFn } from './native/handler';

const logger = getLogger('dataLayer');

export class DataLayer {
  isInitialized = false;

  items: UserEvent[] = [];

  constructor() {
    this.items = getNinjaConfig().dataLayer;
  }

  /**
   * Initialize the dataLayer by adding additional logic to the push method
   */
  init() {
    if (!this.isInitialized) {
      const originalPush = this.items.push;

      this.items.push = (...items: UserEvent[]) => {
        logger.debug('dataLayer.push', ...items);

        // process each item and push it to the dataLayer
        for (const item of items) {
          const pItem = this.processItem(item);
          if (pItem) {
            originalPush.apply(this.items, [pItem]);
          }
        }

        return this.items.length;
      };
      logger.debug('dataLayer initialized');
      this.isInitialized = true;
    }
  }

  processItem(item: UserEvent) {
    logger.debug('Processing item', item);
    const ninjaConfig = getNinjaConfig();
    // item must have one of the special names, which marks it as Ninja event, and not to be already processed
    const eventType = ninjaConfig.specialNames?.find(sn => sn in item);

    if (eventType) {
      if (!item.processed) {
        const eventValue = Array.isArray(item[eventType]) ? item[eventType].join('_') : (item[eventType] as string);
        const userProps: UserProps = omit(item, ...ninjaConfig.specialNames); // remove the special names from the userProps and leave the props

        logger.debug('processing Ninja event:', eventType, eventValue, userProps);
        if (ninjaConfig.isNative) {
          const result = nativeHandlerFn(eventType as HandlerFn, eventValue, userProps);
          logger.debug('nativeHandlerFn result:', result);

          return null;
        }

        if (eventType === 'cleanup') {
          logger.debug('cleanup');
          this.cleanup();

          if (typeof item.cleanup === 'function') {
            logger.debug('cleanup function called');
            item.cleanup();
          }

          // nothing is added back to the dataLayer
          return undefined;
        }

        // take propagation into consideration. This is valid only if we are in a web page
        const isLQEvent = isLaquesisEvent(eventValue);
        const customProps = getCustomParams(userProps, this.items, getNinjaConfig().disablePropertyPropagation || isLQEvent);
        const eventMeta = getEventMeta();
        const emittedEvent = ninjaBus.emit(eventType, eventValue, customProps, eventMeta);

        if (isLQEvent) {
          return null;
        }

        return {
          ...item,
          [TIMESTAMP_PROP]: emittedEvent?.t ?? Date.now(), // mark the event with the timestamp when it was emitted
          processed: true,
        };
      }

      logger.debug('Ninja event already processed:', eventType, item);

      return item;
    }

    // item is not Ninja event and should be ignored
    return item;
  }

  /**
   * Process any existing items in the dataLayer Called right after the dataLayer is initialized
   */
  async processDataLayer() {
    if (this.items.length) {
      logger.debug('processing existing items in dataLayer', this.items);

      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        const processed = this.processItem(item);

        if (processed) {
          this.items[i] = processed;
        }
      }
    }
  }

  push(item: UserEvent) {
    return this.items.push(item);
  }

  /**
   * Cleanup the dataLayer and keep the push() logic.
   * `window.dataLayer` should retain all non-ninja events
   */
  cleanup() {
    let continueRunning = true;

    try {
      while (continueRunning) {
        continueRunning = false;
        for (let i = 0; i < this.items.length; i++) {
          if (isNinjaEvent(this.items[i])) {
            continueRunning = undefined === this.items[i].cleanup;
            this.items.splice(i, 1);
            i = length;
          }
        }
      }
    } catch (error) {
      logger.info(error);
    }
  }
}

export const dataLayer = new DataLayer();
