import { NINJA_CDN } from '@/const/general';
import type { PlatformConfig } from '@/types/configs';
import { fetchJSON } from '@/utils/fetch';

// The bundler will include only the WEB config file. We need the full typings for the bundler's CLI
let regionConfig: PlatformConfig | undefined = undefined;

export async function loadRegionConfig() {
  if (import.meta.env.__REGION_CONFIG__) {
    regionConfig = JSON.parse(import.meta.env.__REGION_CONFIG__) as PlatformConfig;
  } else if (import.meta.env.__REGION__) {
    const regionConfigUrl = `${NINJA_CDN}/config-${JSON.parse(import.meta.env.__REGION__.toLowerCase())}-web.json`;
    regionConfig = await fetchJSON<PlatformConfig>(regionConfigUrl);
  }

  return regionConfig;
}

export function getRegionConfig() {
  return regionConfig;
}
