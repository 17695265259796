import { getNinjaConfig } from '@/config/ninja';
import { getLogger } from '@/logger';
import { objectToQueryString } from './object';

const logger = getLogger('utils/track');

export async function trackWithBeacon(url: string, params: Record<string, unknown>) {
  if (getNinjaConfig().isNative) {
    logger.info('Try to track while in Native env');
    return false;
  }
  const cleanedParams = Object.fromEntries(Object.entries(params).filter(([, value]) => value !== undefined && value !== null));
  const query = objectToQueryString(cleanedParams);

  // hydra POST requires specific body structure
  const hydraPostBody = window.JSON.stringify({
    tracks: [query],
  });

  if (typeof navigator.sendBeacon === 'function') {
    navigator.sendBeacon(url, hydraPostBody);

    logger.debug('Track with Beacon', cleanedParams.tN, cleanedParams.eN, cleanedParams);
    return true;
  } else {
    logger.debug('Track with keepalive fetch', url, query, cleanedParams);
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: hydraPostBody,
      keepalive: true,
    });
    return true;
  }
}
