import {
  type CookieAttributes,
  CookieScope,
  type CookieStorage,
  type CookiesCategorisation,
  ENV,
  createCookieStorage,
  getReservoirLink,
} from '@olxeu-eprivacy-storage/js';
import { getHydraConfig } from '@/config/hydra';
import { getNinjaConfig } from '@/config/ninja';
import { getLogger } from '@/logger';
import { isEprivacyStorageRequired } from '@/utils/consent';
import { fetchJSON } from '@/utils/fetch';
// import { ajaxCall } from '../../old_packages/ninja/utils';
import { REQUIRED_VERSION } from './required-version';

declare global {
  interface Window {
    eprivacyStorage?: Record<string, CookieStorage>;
  }
}

const logger = getLogger('CookieStorage');

/**
 * Get empty facade.
 */
export function getEmptyStorage() {
  return {
    set: (): string | undefined => {
      logger.info('empty storage');
      return undefined;
    },
    get: (): string | undefined => {
      logger.info('empty storage');
      return undefined;
    },
    remove: () => {
      logger.info('empty storage');
    },
  } as CookieStorage;
}

/**
 * Local cookie storage instance.
 */
export let cookieStorage = getEmptyStorage();
export let isReady = false;

/**
 * Every cookie should define these properties
 */
export const defaultCookieAttributes: CookieAttributes = {
  sameSite: 'Lax',
  secure: location.protocol === 'https:',
  path: '/',
  domain: getNinjaConfig().cookieDomain,
};

/**
 * Init local cookie storage - either use the version from `window` or load the definitions
 */
export async function initCookieStorage(): Promise<CookieStorage> {
  if (isReady) {
    return cookieStorage;
  }

  if (isEprivacyStorageRequired()) {
    const ninjaConfig = getNinjaConfig();
    if (typeof ninjaConfig.getEprivacySDK === 'function') {
      cookieStorage = createStorageProxy(ninjaConfig.getEprivacySDK());

      isReady = true;
      return cookieStorage;
    } else if (window.eprivacyStorage?.[REQUIRED_VERSION]) {
      cookieStorage = createStorageProxy(window.eprivacyStorage[REQUIRED_VERSION]);

      isReady = true;
      return cookieStorage;
    } else {
      const storage = await loadCookieStorage();
      cookieStorage = createStorageProxy(storage);

      if (cookieStorage) {
        if (!window.eprivacyStorage) {
          window.eprivacyStorage = {};
        }

        window.eprivacyStorage[REQUIRED_VERSION] = storage;

        isReady = true;
        return cookieStorage;
      }
    }
  } else {
    // If cookie consent is not required for this region, return cookie storage without consent restrictions
    cookieStorage = createStorageProxy(createCookieStorage(false));
    isReady = true;
    return cookieStorage;
  }

  return getEmptyStorage();
}

/**
 * Fetches definition and fully inits the storage
 */
export async function loadCookieStorage() {
  const hydraConfig = getHydraConfig();

  if (hydraConfig.eprivacy_scope) {
    const url = getReservoirLink(CookieScope[hydraConfig.eprivacy_scope], getNinjaConfig().environment == 'production' ? ENV.PRD : ENV.STG);

    try {
      const categories = await fetchJSON<CookiesCategorisation>(url);
      return createCookieStorage(true, categories, false);
    } catch (e) {
      logger.error('Ninja can not load cookie category definitions!', url, e);
      return getEmptyStorage();
    }
  }

  logger.error('Ninja can not load cookie category definitions - missing `eprivacy_scope` for current region!');
  return getEmptyStorage();
}

export function resetStorage() {
  cookieStorage = getEmptyStorage();
  isReady = false;
}

/**
 * Apply default cookie attributes via Proxy
 */
function createStorageProxy(storage: CookieStorage) {
  const handler: ProxyHandler<CookieStorage> = {
    get(target, prop, receiver) {
      const value = target[prop];
      if (value instanceof Function) {
        return function (...args) {
          const newArgs = [...args];

          if (prop === 'set') {
            // args were ommited
            if (newArgs.length === 2) {
              newArgs.push(defaultCookieAttributes);
            } else {
              newArgs[2] = {
                ...defaultCookieAttributes,
                ...newArgs[2],
              };
            }
          }

          return value.apply(this === receiver ? target : this, newArgs);
        };
      }
      return value;
    },
  };

  return new window.Proxy(storage, handler);
}
