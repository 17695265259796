import { getNinjaConfig } from '@/config/ninja';
import { getLogger } from '@/logger';
import { HandlerFn } from '@/types/native';
import * as androidBridge from './androidBridge';
import * as iosBridge from './iosBridge';

const logger = getLogger('native/handler');
/**
 * Checks if the native handlers exist in window and returns the appropriate function
 */
export function getNativeFn(fn: HandlerFn) {
  let fnToInvoke: undefined | ((...args: any[]) => unknown) = undefined;

  if (getNinjaConfig().platform === 'a') {
    fnToInvoke = androidBridge[fn];
  } else if (getNinjaConfig().platform === 'i') {
    fnToInvoke = iosBridge[fn];
  }

  if (typeof fnToInvoke !== 'function') {
    logger.warn(`Function ${fn} not defined in native bridge!`);
    return undefined;
  }

  return fnToInvoke;
}

export function nativeHandlerFn(fn: HandlerFn, ...args: any[]) {
  const fnToInvoke = getNativeFn(fn);

  if (fnToInvoke) {
    return fnToInvoke(...args);
  }

  return undefined;
}
