import { getRegionConfig } from '@/config/region';
import { TRACKER_ABBREVIATIONS } from '@/const/general';
import { UserProps } from '@/types/events';
import { Tracker, TrackerAbbreviation } from '@/types/general';

// This code is for grumpy ninja (we removed the defaultTrackEvent and defaultUseParameter usage)

/**
 * Maps each key (event/property name), and value based on region-specific configurations for a given tracker.
 */
export function makeMapping<T = any>(tracker: Tracker, key: string, value: T, ignoreMappingResult = false) {
  const trackerAbbreviation = TRACKER_ABBREVIATIONS[tracker];
  const mappedKey = mapKey(trackerAbbreviation, key);
  let mappedValue = mapValue(trackerAbbreviation, key, value);

  if (mappedValue === undefined && ignoreMappingResult) {
    mappedValue = value;
  }

  if (mappedKey !== undefined && mappedValue !== undefined) {
    return {
      key: mappedKey,
      value: mappedValue,
    } as { key?: string; value: T };
  }
  return undefined;
}

/**
 * Creates a mapping by iterating over all keys in the given object
 * and calling makeMapping for each key.
 */
export function makeObjectMapping(tracker: Tracker, params: UserProps = {}) {
  return Object.entries(params).reduce<Record<string, any>>((acc, [key, value]) => {
    const mapped = makeMapping(tracker, key, value);

    if (mapped?.key !== undefined && mapped?.value !== undefined) {
      acc[mapped.key] = mapped.value;
    }
    return acc;
  }, {});
}

function mapKey(tracker: TrackerAbbreviation, key: string) {
  const regionConfig = getRegionConfig();

  return regionConfig?.map?.[key]?.N?.[tracker];
}

function mapValue(tracker: TrackerAbbreviation, key: string, value: any) {
  if (['trackPage', 'trackEvent'].includes(key)) {
    return mapEventValue(tracker, key, value);
  }

  return mapPropertyValue(tracker, key, value);
}

/**
 * Maps event value for a given tracker.
 */
function mapEventValue(tracker: TrackerAbbreviation, key: string, value: any) {
  const regionConfig = getRegionConfig();

  return regionConfig?.map?.[key]?.V?.[value]?.[tracker];
}

/**
 * Maps a property value for a given tracker or returns the original value if
 * the key exists but no value mapping is found.
 */
function mapPropertyValue(tracker: TrackerAbbreviation, key: string, value: any) {
  const regionConfig = getRegionConfig();

  if (!regionConfig?.map?.[key]) {
    return undefined;
  }
  const mappedValue = regionConfig?.map?.[key]?.V?.[value]?.[tracker];

  return mappedValue ?? value;
}
