import { getHydraConfig } from '@/config/hydra';
import { getNinjaConfig } from '@/config/ninja';
import { SPLITTER_CDN } from '@/const/general';
import { LQ_SURVEYS_STORAGE_KEY, LQ_SURVEY_TRIGGERS_STORAGE_KEY, LQ_SURVEY_TYPES_STORAGE_KEY } from '@/const/laquesis';
import { getLQDefinition, writeLQDefinition } from '@/cookies/laquesis/lqstatus';
import { getLogger } from '@/logger';
import { state } from '@/ninja/state';
import { SurveySetup } from '@/types/surveys';
import { fetchJSON } from '@/utils/fetch';
import { objectToQueryString } from '@/utils/object';

const logger = getLogger('Surveys/storage');

export function initSurveyStorage() {
  if (state.playgroundMode) {
    return;
  }
  // TODO: Remove this clean up at some point
  // cleanup old items from store
  localStorage.removeItem(LQ_SURVEY_TRIGGERS_STORAGE_KEY);
  localStorage.removeItem(LQ_SURVEY_TYPES_STORAGE_KEY);

  const storedSurveys = localStorage.getItem(LQ_SURVEYS_STORAGE_KEY) || '';
  const surveysData = parseLaquesisSuData(storedSurveys);

  if (!surveysData) {
    logger.debug('No surveys data found in local storage, setting refresh flag to true');
    state.isLQRefreshNeeded = true;
    return;
  }
  assignSurveys(surveysData);
}

export function parseLaquesisSuData(storedSurveys: string) {
  if (!storedSurveys) {
    return null;
  }

  try {
    const parsed: SurveySetup[] = JSON.parse(storedSurveys);
    logger.debug('Parsed surveys data from local storage', parsed);
    if (Array.isArray(parsed)) {
      return parsed;
    }
  } catch (error) {
    logger.error('Failed to parse surveys data from local storage', error);
  }
  return null;
}

/**
 * Load survey data from splitter
 * @param link - optional link (used when previewing a survey using window.laquesisShowSurveyByLink)
 */
export async function loadSurveyData(surveyId: number, link = '') {
  if (link) {
    return fetchJSON<Required<SurveySetup>>(link);
  }

  const params = {
    id: surveyId.toString(),
    cc: getHydraConfig().params?.cC,
    br: getHydraConfig().params?.bR,
    ch: getNinjaConfig().platform,
  };
  const url = `${SPLITTER_CDN}/survey?${objectToQueryString(params)}`;
  return fetchJSON<Required<SurveySetup>>(url);
}

/**
 * Assigns the survey selected using the playground
 * Laquesis processes the qa hash and triggers this function if a survey was selected
 */
export async function assignQASurvey(id: number) {
  const data = await loadSurveyData(id);
  if (!data) {
    logger.debug('Survey data not found');
    return;
  }
  assignSurveys([data]);
}

/**
 * Process the splitter response (or local storage stored items) and update storage with active surveys
 */
export function assignSurveys(surveys: SurveySetup[]) {
  const lqDefinition = getLQDefinition();

  const sortedSurveys = [...surveys].sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
  const mappedSurveys = sortedSurveys.map(survey => ({
    id: Number(survey.id),
    triggers: survey.triggers,
    type: survey.type,
  }));
  state.activeSurveys = mappedSurveys;
  window.localStorage.setItem(LQ_SURVEYS_STORAGE_KEY, JSON.stringify(mappedSurveys));
  logger.debug('laquesissu updated', mappedSurveys);
  state.surveyTriggerMap = buildTriggerMap(mappedSurveys);

  // Clean up the showed surveys that are not active anymore
  lqDefinition.showedSurveys = lqDefinition.showedSurveys.filter(id => surveys.some(survey => survey.id.toString() === id));
  writeLQDefinition(lqDefinition);
}

/**
 * Extract trigger events for easy use. Note that feedback and functional surveys will not be in this list.
 */
function buildTriggerMap(surveys: SurveySetup[]) {
  const triggerMap = surveys.reduce(
    (acc, survey) => {
      survey.triggers.forEach(({ event_name }) => {
        acc[event_name] = acc[event_name] ?? [];
        acc[event_name].push(survey.id);
      });
      return acc;
    },
    {} as Record<string, number[]>
  );
  logger.debug('Survey trigger map created', triggerMap);
  return triggerMap;
}

/**
 * Get survey by id
 */
export function getSurvey(id: number | string) {
  logger.debug('Getting survey from active surveys', id, state.activeSurveys);
  return state.activeSurveys.find(s => s.id.toString() === id.toString());
}

/**
 * Get surveys with specific trigger event
 */
export function getSurveysWithTriggerEvent(eventName: string) {
  return state.surveyTriggerMap[eventName] ?? [];
}

export function clearSurveyStorage() {
  assignSurveys([]);
  localStorage.removeItem(LQ_SURVEYS_STORAGE_KEY);
}
