// https://naspersclassifieds.atlassian.net/wiki/spaces/DATA/pages/57720538471/Ninja+iOS#Webviews-(v1.2.5%2B)
import { parseLaquesisCookie, parseLaquesisFfCookie } from '@/cookies/laquesis';
import { UserProps } from '@/types/events';
import { omit } from '@/utils/object';

type IOSWebview = typeof window & {
  webkit?: {
    messageHandlers: {
      trackView: {
        postMessage: (data: { name: string; params: UserProps }) => void;
      };
      trackEvent: {
        postMessage: (data: { name: string; params: UserProps }) => void;
      };
      getVariant: {
        postMessage: (data: { name: string }) => void;
      };
      isFlagEnabled: {
        postMessage: (data: { name: string }) => void;
      };
    };
  };
  LaquesisIOS?: {
    experiments: string;
    flags: string;
  };
};
const theWindow = window as IOSWebview;

/**
 * Tracks an event by forwarding the message to the native iOS app
 */
export const trackPage = (name: string, extraData: UserProps = {}) => {
  const props = omit(extraData, 'trackPage');
  return theWindow?.webkit?.messageHandlers?.trackView.postMessage({
    name,
    params: props,
  });
};

export const trackEvent = (name: string, extraData: UserProps = {}) => {
  const props = omit(extraData, 'trackEvent');
  return theWindow?.webkit?.messageHandlers?.trackEvent.postMessage({
    name,
    params: props,
  });
};

export const trackEventDelayed = trackEvent;

// Laquesis
// @see https://git.naspersclassifieds.com/global-data-services/collection/ninja/ios-ninja/-/merge_requests/490
/**
 * iOS brdge methods are async by default. We post the message to trigger any native logic,
 * but use the exposed experiments from iOS to retrieve the real value syncronously.
 */
export const getLaquesisVariant = (name: string) => {
  theWindow?.webkit?.messageHandlers?.getVariant.postMessage({
    name,
  });

  if (theWindow.LaquesisIOS?.experiments) {
    const active = parseLaquesisCookie(theWindow.LaquesisIOS.experiments);

    return active.get(name);
  }

  return undefined;
};

/**
 * iOS brdge methods are async by default. We post the message to trigger any native logic,
 * but use the exposed flags from iOS to retrieve the real value syncronously.
 * @param {string} name
 * @returns {boolean}
 */
export const isFeatureEnabled = (name: string) => {
  theWindow?.webkit?.messageHandlers?.isFlagEnabled.postMessage({
    name,
  });

  if (theWindow.LaquesisIOS?.flags) {
    const active = parseLaquesisFfCookie(theWindow.LaquesisIOS.flags);

    return active.has(name);
  }

  return false;
};

export const flushDelayed = () => {};
