import type { NinjaUserConfig } from '@/types/global';

const defaultConfig: Partial<NinjaUserConfig> = {
  surveys: {
    accept: 'Sure',
    decline: 'Not now',
    finish: 'Finish',
    next: 'Next',

    checkBoxGroupHint: 'Select one or more options',
    multiLineInputHint: 'Add a comment',
    radioGroupHint: 'Select one option',
    singleInputHint: 'Add a comment',
  },
};

function getConfigTracking(config: Partial<NinjaUserConfig> = {}): Partial<NinjaUserConfig> {
  const surveys = {
    ...defaultConfig.surveys,
    ...config.surveys,
  };

  return {
    ...defaultConfig,
    ...config,
    surveys,
  };
}

export const configTracking = getConfigTracking(window.configTracking);
