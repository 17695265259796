import { LAQUESIS_SURVEY_JS_URL } from '@/const/general';
import { getLogger } from '@/logger/logger';
import { loadScript } from '@/utils/browser';

const logger = getLogger('Surveys/scriptLoader');

export let isLoaded = false;

export async function loadSurveyScript() {
  if (isLoaded) {
    return;
  }
  try {
    await loadScript(LAQUESIS_SURVEY_JS_URL, 'laquesisSurvey');
    isLoaded = true;
  } catch (error) {
    logger.error('Loading survey script failed', error);
    throw new Error('Loading survey script failed');
  }
}

export function resetIsLoaded() {
  isLoaded = false;
}
