import { getHydraConfig } from '@/config/hydra';
import { getNinjaConfig } from '@/config/ninja';
import { CUSTOM_EVENTS, GENERAL_EVENTS, INTERNAL_EVENTS } from '@/const/events';
import { SPLITTER_CDN } from '@/const/general';
import { LAQUESIS_COOKIE_NAMES, TEST_DIVIDER, VARIANT_DIVIDER } from '@/const/laquesis';
import { cookieStorage } from '@/cookies/cookieStorage';
import { getCookieExpirationDate } from '@/cookies/getCookieExpirationDate';
import { getLQDefinition, writeLQDefinition } from '@/cookies/laquesis/lqstatus';
import { ninjaBus } from '@/eventbus';
import { getLogger } from '@/logger';
import { state } from '@/ninja/state';
import { LQAssignments } from '@/types/laquesis';
import { triggerCustomEvent } from '@/utils/event';
import { fetchJSON } from '@/utils/fetch';
import { objectToQueryString } from '@/utils/object';

const logger = getLogger('laquesis/definition');

export async function reloadLaquesisDefinitions() {
  logger.debug('reload definitions');
  const definitions = await fetchDefinitions();
  if (!Object.keys(definitions).length) {
    return;
  }
  processDefinition(definitions);
}

export async function fetchDefinitions() {
  const params: Record<string, any> = {
    sl: state.currentSession?.sessionLong,
    br: getHydraConfig().params?.bR,
    cc: getHydraConfig().params?.cC,
    ch: getNinjaConfig().platform,
  };

  if (state.userId) {
    params.ui = state.userId;
  }
  const url = `${SPLITTER_CDN}/assign?${objectToQueryString(params)}`;
  logger.debug('fetch definitions', url);

  return fetchJSON<LQAssignments>(url);
}

export function processDefinition(assignments: LQAssignments) {
  ninjaBus.emit(INTERNAL_EVENTS.ASSIGN_SURVEYS, '', { surveys: assignments.surveys });
  const newExpsCookieValue = getExpsCookieValue(assignments.tests);
  const newFlagsCookieValue = getFlagsCookieValue(assignments.flags);

  // Set the next refresh
  const lqDefinition = getLQDefinition();
  lqDefinition.nextRefresh = Date.now() + (assignments.config?.next_update_foreground_in_minutes ?? 0) * 60 * 1000;
  writeLQDefinition(lqDefinition);

  // Update the cookies and track the new assignments if they have changed
  const expsCookieValue = cookieStorage.get(LAQUESIS_COOKIE_NAMES.LAQUESIS);
  if (expsCookieValue !== newExpsCookieValue) {
    cookieStorage.set(LAQUESIS_COOKIE_NAMES.LAQUESIS, newExpsCookieValue, {
      expires: getCookieExpirationDate(1, 'year'),
      path: '/',
      domain: getNinjaConfig().cookieDomain,
    });

    ninjaBus.emit(INTERNAL_EVENTS.TRACK_EXP_ASSIGNMENT, '');
    triggerCustomEvent(CUSTOM_EVENTS.LAQUESIS_EXP_ASSIGNMENT, { experiments: newExpsCookieValue });
  }

  const flagsCookieValue = cookieStorage.get(LAQUESIS_COOKIE_NAMES.LAQUESIS_FF);
  if (flagsCookieValue !== newFlagsCookieValue) {
    cookieStorage.set(LAQUESIS_COOKIE_NAMES.LAQUESIS_FF, newFlagsCookieValue, {
      expires: getCookieExpirationDate(1, 'year'),
      path: '/',
      domain: getNinjaConfig().cookieDomain,
    });

    ninjaBus.emit(INTERNAL_EVENTS.TRACK_FLAG_ASSIGNMENT, '');
    triggerCustomEvent(CUSTOM_EVENTS.LAQUESIS_FLAG_ASSIGNMENT, { flags: newFlagsCookieValue });
  }

  // Call function when assign is loaded and ready to use. @deprecated
  if (typeof window.laquesisAssignCallback === 'function') {
    ninjaBus.emit(GENERAL_EVENTS.TRACK_FEATURE_USAGE, '', { message: 'laquesis/laquesisAssignCallback' });
    window.laquesisAssignCallback.call(null);
  }
  triggerCustomEvent(CUSTOM_EVENTS.LAQUESIS_ASSIGNEMENTS_READY, { experiments: newExpsCookieValue, flags: newFlagsCookieValue });
}

export function getExpsCookieValue(exps: LQAssignments['tests']) {
  exps.sort((a, b) => a.tN.localeCompare(b.tN));
  state.activeExps.clear();

  const result = exps
    .map(exp => {
      const tN = exp.tN.toLowerCase();
      const vN = exp.vN.toLowerCase();
      state.activeExps.set(tN, vN);
      return TEST_DIVIDER + tN + VARIANT_DIVIDER + vN;
    })
    .join('');
  return result.slice(1);
}

export function getFlagsCookieValue(flags: LQAssignments['flags']) {
  flags.sort((a, b) => a.tN.localeCompare(b.tN));
  state.activeFlags.clear();

  const result = flags
    .map(flag => {
      const tN = flag.tN.toLowerCase();
      state.activeFlags.add(tN);
      return TEST_DIVIDER + tN;
    })
    .join('');
  return result.slice(1);
}
