import { getHydraConfig } from '@/config/hydra';
import { getNinjaConfig } from '@/config/ninja';
import { getRegionConfig } from '@/config/region';
import { getLogger } from '@/logger';
import { Event } from '@/types/events';
import { DefaultParams } from '@/types/params';

const _logger = getLogger('ninja/params');

/**
 * Get list of default properties shared between trackers
 */
export function getNinjaDefaultParams(event: Event) {
  const hydraConfig = getHydraConfig();
  const regionConfig = getRegionConfig();
  const defaultParams: Partial<DefaultParams> = {};

  if (hydraConfig?.params?.cC) {
    defaultParams.cC = hydraConfig.params.cC;
  }
  if (hydraConfig?.params?.bR) {
    defaultParams.bR = hydraConfig.params.bR;
  }

  // Matrix Version
  if (regionConfig?.version) {
    defaultParams.mv = regionConfig.version;
  }

  // ninja version - we don't increase the semver of the packege. Use the commit hash
  if (import.meta.env.__GIT_HASH__) {
    defaultParams.ninjaVer = import.meta.env.__GIT_HASH__;
  }

  // Environment
  if (getNinjaConfig().environment !== 'production') {
    defaultParams.env = 'dev';
  }

  // eventType
  if (event.actionType == 'trackEvent') {
    defaultParams.tN = 'e';
  } else if (event.actionType == 'trackPage') {
    defaultParams.tN = 'p';
  }

  return defaultParams;
}
