import { getLogger } from '@/logger';

const logger = getLogger('utils/browser');

export function getQueryParams() {
  return new URLSearchParams(window.location.search);
}

/**
 * Inserts a script in the document
 */
export function loadScript(url: string, id: string, appendTo: HTMLElement = document.head): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const oldTag = document.getElementById(id);
    if (oldTag) {
      resolve(true);
      return;
    }

    if (typeof document.createElement === 'function') {
      try {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.id = id;
        script.src = url;
        script.addEventListener('load', () => {
          logger.debug(`Script loaded: ${url} with id=${id}`);
          resolve(true);
        });
        script.addEventListener('error', () => {
          logger.error(`Script NOT loaded: ${url} with id=${id}`);
          reject(`Failed to load script: ${id}`);
        });

        appendTo.appendChild(script);
      } catch (e) {
        reject(e);
      }
    }
  });
}

export function getCurrentPath() {
  let pathname = window.location.pathname;
  if (pathname.charAt(0) === '/' && pathname.length > 1) {
    pathname = pathname.slice(1);
  }
  if (pathname.charAt(pathname.length - 1) === '/' && pathname.length > 1) {
    pathname = pathname.slice(0, pathname.length - 1);
  }
  if (pathname.search(/\./) > 0) {
    pathname = pathname.replace(/(\.[a-z]+$)/, '');
  }
  if (pathname === '/' || pathname === '') {
    pathname = 'home';
  }
  return pathname;
}

export function isStorageAvailable() {
  try {
    const storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch {
    return false;
  }
}

export async function loadCSS(url: string, id: string, appendTo: HTMLElement = document.head) {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve(true);
    } else {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = url;
      link.id = id;
      link.addEventListener('load', () => {
        logger.debug(`css loaded: ${url} with id=${id}`);
        resolve(true);
      });
      link.addEventListener('error', () => {
        logger.error(`css NOT loaded: ${url} with id=${id}`);
        reject(`Failed to load css: ${id}`);
      });

      appendTo.appendChild(link);
    }
  });
}
