import { getNinjaConfig } from '@/config/ninja';
import { LQ_EVENTS } from '@/const/events';
import { getSessionParams } from '@/ninja/sessionManager';
import { Event, EventMeta } from '@/types/events';
import { getInvite, getItmInvite } from './campaigns';
import { isObject } from './object';

/**
 * Triggers CustomEvent on an element, the `document` by default
 */
export function triggerCustomEvent(eventName: string, detail?: any, element: HTMLElement | Document = document) {
  const event = new CustomEvent(eventName, { detail });

  element.dispatchEvent(event);
}

/**
 * Check if the object is a Ninja event - non-ninja events are not processed
 */
export function isNinjaEvent(obj: any) {
  if (!isObject(obj)) {
    return false;
  }
  return getNinjaConfig().specialNames?.find(sn => sn in obj) !== undefined;
}

/**
 * Gets event meta, attached to every event emitted by the dataLayer. Do not use directly
 */
export function getEventMeta() {
  return {
    invite: getInvite(),
    itm: getItmInvite(), // needs to be mapped
    host: window.location.host,
    hash: window.location.hash,
    referrer: document.referrer,
    sessionParams: getSessionParams(),
  } as EventMeta;
}

/**
 * Ensures the event has meta field.
 */
export function ensureEventMeta<T>(e: Event<T>) {
  if (e.meta) {
    return e;
  }
  return {
    ...e,
    meta: getEventMeta(),
  };
}

/**
 * Checks if this is special laquesis-triggered event
 */
export function isLaquesisEvent(event: string) {
  return Object.values(LQ_EVENTS).includes(event);
}
