// consider all possible non-latin chars - https://github.com/dundalek/latinize/blob/master/latinize.js
export const LATIN_MAP = {
  '\u00e1': 'a', // á
  '\u00c1': 'A', // Á
  '\u00e9': 'e', // é
  '\u00c9': 'E', // É
  '\u00ed': 'i', // í
  '\u00cd': 'I', // Í
  '\u00f3': 'o', // ó
  '\u00d3': 'O', // Ó
  '\u00fa': 'u', // ú
  '\u00da': 'U', // Ú
  '\u00f1': 'n', // ñ
  '\u00d1': 'N', // Ñ
};
