import { getNinjaConfig } from '@/config/ninja';
import { GENERAL_EVENTS, INTERNAL_EVENTS } from '@/const/events';
import { LAQUESIS_COOKIE_NAMES, PLAYGROUND_COOKIES_EXPIRATION, SECTION_DIVIDER } from '@/const/laquesis';
import { cookieStorage } from '@/cookies/cookieStorage';
import { getCookieExpirationDate } from '@/cookies/getCookieExpirationDate';
import { ninjaBus } from '@/eventbus';
import { getLogger } from '@/logger';
import { state } from '@/ninja/state';

const logger = getLogger('Laquesis/playground');

/**
 * Set cookies based on the values passed through the laquesisqahash
 * Hash order: experiments with respective variants, env, flags, up to one survey
 * @param {string} laquesisQaHash 'EXP-1@b#EXP-2@a!production!FLAG-1#FLAG-2!123'
 */
export function handlePlaygroundParams(laquesisQaHash: string) {
  const decodedParams = window.atob(decodeURIComponent(laquesisQaHash));

  const playgroundParams = decodedParams.split(SECTION_DIVIDER);
  if (playgroundParams.length <= 4) {
    // playgroundParams[1] is the environment (this info is currently not used)
    const [experiments, , flags, survey] = playgroundParams;

    if (experiments) {
      logger.debug('Enabling the playground mode with experiments:', experiments);
      // Save in the cookie to enable backend experiments
      cookieStorage.set(LAQUESIS_COOKIE_NAMES.LAQUESIS, experiments.toLowerCase(), {
        expires: getCookieExpirationDate(PLAYGROUND_COOKIES_EXPIRATION, 'min'),
        path: '/',
        domain: getNinjaConfig().cookieDomain,
      });
    }

    if (flags) {
      logger.debug('Enabling the playground mode with flags:', flags);
      // Save in the cookie to enable backend flags
      cookieStorage.set(LAQUESIS_COOKIE_NAMES.LAQUESIS_FF, flags.toLowerCase(), {
        expires: getCookieExpirationDate(PLAYGROUND_COOKIES_EXPIRATION, 'min'),
        path: '/',
        domain: getNinjaConfig().cookieDomain,
      });
    }

    if (survey) {
      logger.debug('Enabling the playground mode with a survey:', survey);
      ninjaBus.emit(INTERNAL_EVENTS.ASSIGN_QA_SURVEY, '', { surveyId: Number(survey) });
    }

    if (experiments || flags || survey) {
      // Flag that it is in playground mode so the definitions refresh is not triggered
      state.playgroundMode = true;
      ninjaBus.emit(GENERAL_EVENTS.TRACK_FEATURE_USAGE, '', {
        message: `laquesis/playground – experiments: ${experiments}, flags: ${flags}, survey: ${survey}`,
      });
    }
  }
}
