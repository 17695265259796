import { GENERAL_EVENTS } from '@/const/events';
import { parseLaquesisCookie } from '@/cookies/laquesis';
import { ninjaBus } from '@/eventbus';
import { state } from '@/ninja/state';

/**
 * This is needed for the cookie watcher feature
 * @see https://naspersclassifieds.atlassian.net/wiki/x/XILGhA0
 */
export function overrideLaquesisResultsPush(callback: (name: string, variant: string) => void) {
  window.laquesisResults = window.laquesisResults || [];

  const currentLqPush = window.laquesisResults.push;
  window.laquesisResults.push = params => {
    const result = currentLqPush.apply(window.laquesisResults, [params]);
    ninjaBus.emit(GENERAL_EVENTS.TRACK_FEATURE_USAGE, '', { message: 'laquesis/cookie watcher used: ' + params.newResult });
    checkResults(callback);
    return result;
  };
}

/**
 * Check if there are new items in the laquesisResults array and process them
 */
export function checkResults(callback: (name: string, variant: string) => void) {
  if (window.laquesisResults) {
    for (const data of window.laquesisResults) {
      if (typeof data === 'object' && !data.processed && data.newResult !== undefined) {
        data.processed = true;
        const newTests = parseLaquesisCookie(data.newResult, callback);
        state.activeExps = new Map([...state.activeExps, ...newTests]);
      }
    }
  }
}
