import { getNinjaConfig } from '@/config/ninja';
import { LAQUESIS_SURVEY_CSS_FILENAME, NINJA_CDN } from '@/const/general';
import { getLogger } from '@/logger/logger';
import { loadCSS } from '@/utils/browser';

const logger = getLogger('Surveys/stylesLoader');

export let isCSSLoaded = false;

export async function loadSurveyStyles() {
  const ninjaConfig = getNinjaConfig();

  if (!ninjaConfig.asyncSurveyStyles || isCSSLoaded) {
    return;
  }

  try {
    const theme = ninjaConfig.surveyTheme || 'default';
    const filename = `${LAQUESIS_SURVEY_CSS_FILENAME}-${theme}.css`;
    const url = `${NINJA_CDN}/${filename}`;

    await loadCSS(url, 'laquesisSurveyStyles');
    logger.debug('Loaded survey styles', url);
    isCSSLoaded = true;
  } catch (error) {
    logger.error('Failed to load survey styles', error);
    throw new Error('Failed to load survey styles');
  }
}

export function resetIsCSSLoaded() {
  isCSSLoaded = false;
}
