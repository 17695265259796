import { LQ_EVENTS } from '@/const/events';
import { TEST_DIVIDER } from '@/const/laquesis';
import { getLQDefinition, writeLQDefinition } from '@/cookies/laquesis/lqstatus';
import { getLogger } from '@/logger';
import { dataLayer } from '@/ninja/dataLayer';
import { state } from '@/ninja/state';
import { ClickEvent } from '@/types/events';
import { hasUserInteractedWithPage } from '@/web-vitals';

const logger = getLogger('Laquesis/tracking');

export function trackExpsAssignment() {
  const expDefinition = Array.from(state.activeExps)
    .map(([k, v]) => `"${k},${v}"`)
    .join(',');

  dataLayer.push({
    trackEvent: LQ_EVENTS.TEST_ASSIGNMENT,
    test_definition: `[${expDefinition}]`,
  });
}

export function trackFlagsAssignment() {
  const flagDefinition = `[${[...state.activeFlags].map(flag => `"${flag}"`).join(',')}]`;

  dataLayer.push({
    trackEvent: LQ_EVENTS.FLAG_ASSIGNMENT,
    flag_definition: flagDefinition,
  });
}

export function trackImpression(expName: string, variantName: string) {
  if (state.playgroundMode) {
    logger.debug('Playground mode enabled, skipping tracking impression');
    return;
  }

  const lqDefinition = getLQDefinition();

  // If the session is not defined or mismatched, reset the tracked experiments
  if (!lqDefinition.currentSession || lqDefinition.currentSession !== state.currentSession?.session) {
    lqDefinition.currentSession = state.currentSession!.session;
    lqDefinition.trackedExperiments = '';
  } else if (lqDefinition.trackedExperiments && lqDefinition.trackedExperiments.includes(expName)) {
    // If the experiment is already tracked, return
    return;
  }

  // Append new experiment
  lqDefinition.trackedExperiments = lqDefinition.trackedExperiments ? lqDefinition.trackedExperiments + TEST_DIVIDER + expName : expName;

  writeLQDefinition(lqDefinition);

  const expDefinition = Array.from(state.activeExps)
    .map(([k, v]) => `"${k},${v}"`)
    .join(',');

  const params: ClickEvent = {
    trackEvent: LQ_EVENTS.TEST_IMPRESSION,
    test_name: expName,
    test_variation: variantName,
    test_definition: expDefinition,
  };

  if (!hasUserInteractedWithPage) {
    // Needed for web vitals to be linked to experiments
    params.web_vital_page = state.firstTrackedPageEvent;
  }

  dataLayer.push(params);
  logger.debug('Impression tracked', params);
}
