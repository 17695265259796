import { getLogger } from '@/logger';
import { Event } from '@/types/events';
import { makeMapping, makeObjectMapping } from '@/utils/mapping';
import { objectToQueryString } from '@/utils/object';
import { BaseTracker } from './BaseTracker';

const logger = getLogger('FacebookTracker');

export class FacebookTracker extends BaseTracker {
  constructor() {
    super();

    this.scriptUrl = 'https://www.facebook.com/tr';
    this.trackerName = 'facebook';
    this.listeners = {
      trackEvent: e => this.initAndExecute(() => this.trackEvent(e)),
      trackPage: e => this.initAndExecute(() => this.trackPage(e)),
      trackLinkEvent: e => this.initAndExecute(() => this.trackLinkEvent(e)),
    };
  }

  async init() {
    try {
      if (!this.hasRequiredConsent()) {
        return this;
      }
      if (!this.isInitialized) {
        this.isInitialized = true;
        logger.debug('Facebook tracker initialized');
      }

      return this;
    } catch (err) {
      logger.error('Facebook tracker initialization error', err);
      throw err;
    }
  }

  async trackEvent(e: Event) {
    if (!this.hasRequiredConsent()) {
      logger.debug('No consent from the user. Event tracking aborted', e);
      return;
    }
    if (!this.isInitialized) {
      try {
        await this.init();
      } catch (err) {
        logger.error('Failed to initialize Facebook tracker. Event tracking aborted', err);
        return;
      }
    }

    const mappedEvent = makeMapping(this.trackerName, 'trackEvent', e.value);

    if (!mappedEvent) {
      logger.debug('Values not found in the mapping. Event tracking aborted', e.value);
      return;
    }

    const url = this.getUrl(mappedEvent.value, e);
    if (url) {
      // Do not track errors - it can have false positives.
      await fetch(url);
      logger.debug('Facebook event tracked', e.actionType, e.value, e.props);
    }
  }

  async trackPage(e: Event) {
    if (!this.hasRequiredConsent()) {
      logger.debug('No consent from the user. Page tracking aborted', e);
      return;
    }
    if (!this.isInitialized) {
      try {
        await this.init();
      } catch (err) {
        logger.error('Failed to initialize Facebook tracker. Page tracking aborted', err);
        return;
      }
    }

    const mappedPage = makeMapping(this.trackerName, 'trackPage', e.value);
    if (!mappedPage) {
      logger.debug('Values not found in the mapping. Page tracking aborted', e.value);
      return;
    }
    const pageName = decodeURIComponent(mappedPage.value);

    const url = this.getUrl(pageName, e);
    if (url) {
      // Do not track errors - it can have false positives.
      await fetch(url);
      logger.debug('Facebook page tracked', e.actionType, e.value, e.props);
    }
  }

  /**
   * Generates the URL for tracking by combining the base URL, tracking parameters and additional parameters passed in.
   */
  getUrl(eventName: string, params: Event) {
    const queryParams = {
      id: this.getToken(),
      ev: eventName,
    };

    const queryString = objectToQueryString(queryParams);
    const paramQuery = this.getUrlParams(params);

    return `${this.scriptUrl}?${queryString}${paramQuery}`;
  }

  /**
   * Get formatted URL params
   * Creates a mapping of custom params, and encoding them for use in a GET request
   */
  getUrlParams(params: Event) {
    const urlParams = Object.entries(makeObjectMapping(this.trackerName, params.props)).reduce(
      (acc, [key, value]) => {
        acc[encodeURIComponent(key)] = encodeURIComponent(value);
        return acc;
      },
      {} as Record<string, string>
    );

    const paramString = Object.entries(urlParams)
      .map(([key, value]) => `&cd[${key}]=${value}`)
      .join('');

    return paramString;
  }
}
