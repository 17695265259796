import { CUSTOM_EVENTS } from '@/const/events';
import { ninjaBus } from '@/eventbus';
import { getLogger } from '@/logger/logger';
import { state } from '@/ninja/state';
import type { SurveySetup } from '@/types/surveys';
import { triggerCustomEvent } from '@/utils/event';
import { markSurveyAsShown } from './conditions';
import { loadSurveyScript } from './loadSurveyScript';
import { loadSurveyStyles } from './loadSurveyStyles';
import { loadSurveyData } from './storage';

const logger = getLogger('Surveys/display');

export async function triggerSurvey(surveyId: number, link = '') {
  if (state.triggeredSurvey) {
    logger.debug('Another survey already active, triggering aborted.');
    return;
  }

  const loadingStartTimestamp = Date.now();
  try {
    const [data] = await Promise.all([loadSurveyData(surveyId, link), loadSurveyStyles(), loadSurveyScript()]);
    state.triggeredSurvey = data;

    await handleDelay(data, loadingStartTimestamp);
    console.assert(typeof window.laquesisSurvey === 'function', 'laquesisSurvey not found in window!');

    triggerCustomEvent(CUSTOM_EVENTS.LAQUESIS_SURVEY_SHOW, { id: data.id });
    window.laquesisSurvey(data, ninjaBus, state.playgroundMode);

    if (!state.playgroundMode) {
      markSurveyAsShown(data);
    }
  } catch (error) {
    state.triggeredSurvey = undefined;
    logger.error('Failed to trigger survey', error);
    throw error;
  }
}

/**
 * Allows to delay the survey rendering by a given number of seconds, taking into account the time it took to load the survey.
 */
export async function handleDelay(surveyData: SurveySetup, loadingStartTimestamp: number) {
  if (!surveyData) {
    throw new Error('No survey data provided');
  }
  const delayRender = surveyData.config?.delay_render_in_sec ? surveyData.config.delay_render_in_sec * 1000 : 3000;

  const timeLoading = Date.now() - loadingStartTimestamp;
  const timeRemaining = Math.max(0, delayRender - timeLoading);

  await new Promise(resolve => setTimeout(resolve, timeRemaining));
}
