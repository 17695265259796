import { getNinjaConfig } from '@/config/ninja';
import { getRegionConfig } from '@/config/region';
import { CUSTOM_EVENTS } from '@/const/events/customEvents';
import { TRACKER_ABBREVIATIONS } from '@/const/general';
import { getLogger } from '@/logger';
import { Event } from '@/types/events';
import { triggerCustomEvent } from '@/utils/event';
import { makeMapping } from '@/utils/mapping';
import { BaseTracker } from './BaseTracker';
import { initBraze } from './init-snippets/initBraze';

const logger = getLogger('BrazeTracker');

export class BrazeTracker extends BaseTracker {
  isDisabled: boolean;

  constructor() {
    super();

    this.isDisabled = false;
    this.isInitPushed = false;
    this.trackerName = 'braze';
    this.scriptTagId = 'braze-script-tag';
    this.scriptUrl = 'https://js.appboycdn.com/web-sdk/5.7/braze.min.js';
    this.listeners = {
      trackEvent: e => this.initAndExecute(() => this.trackEvent(e)),
      trackPage: e => this.initAndExecute(() => this.trackPage(e)),
      trackLinkEvent: e => this.initAndExecute(() => this.trackLinkEvent(e)),
    };
  }

  async init() {
    try {
      if (!this.hasRequiredConsent()) {
        return this;
      }

      const code = this.getToken();
      const endpoint = this.getEndpoint();
      const oldTag = document.getElementById(this.scriptTagId as string);
      this.isInitPushed = !!oldTag;

      if (!this.isInitPushed) {
        this.isInitPushed = true;
        await initBraze(window, document, 'script', null, null, this.scriptUrl, this.scriptTagId);
        if (window.braze) {
          this.enableAndInitialize(code as string, endpoint as string);
          // TODO: remove custom callback
          if (typeof window.brazeInitCallback === 'function') {
            window.brazeInitCallback(window.braze);
          }
          triggerCustomEvent(CUSTOM_EVENTS.BRAZE_INIT, window.braze);
          this.isInitialized = true;
          logger.debug('Braze tracker initialized');
        } else {
          logger.debug('window.braze is missing after init');
        }
      } else {
        if (this.isDisabled && window.braze) {
          // when enabling the disabled Braze tracker we need to initialize it once again
          this.enableAndInitialize(code as string, endpoint as string);
        }
      }
    } catch (err) {
      logger.error('Braze tracker initialization error', err);
      throw err;
    }
    return this;
  }

  async trackEvent(e: Event) {
    if (!this.hasRequiredConsent()) {
      this.cleanup();
      logger.debug('No consent from the user. Event tracking aborted', e);
      return;
    }
    if (!this.isInitialized) {
      try {
        await this.init();
      } catch (err) {
        logger.error('Failed to initialize Braze tracker. Event tracking aborted', err);
        return;
      }
    }

    const mappedEvent = makeMapping(this.trackerName, 'trackEvent', this.convertString(e.value));
    if (!mappedEvent) {
      logger.debug('Values not found in the mapping. Event tracking aborted', e.value);
      return;
    }

    if (window.braze && typeof window.braze.logCustomEvent === 'function') {
      const trackParams = this.getTrackParams(e);
      const isLoggingSuccessful = window.braze.logCustomEvent(mappedEvent.value, trackParams);

      if (!isLoggingSuccessful) {
        logger.debug('Braze event tracking failed', e.actionType, e.value, e.props);
      } else {
        logger.debug('Braze event tracked', e.actionType, e.value, e.props);
      }
    }
  }

  async trackPage(e: Event) {
    if (!this.hasRequiredConsent()) {
      this.cleanup();
      logger.debug('No consent from the user. Page tracking aborted', e);
      return;
    }
    if (!this.isInitialized) {
      try {
        await this.init();
      } catch (err) {
        logger.error('Failed to initialize Braze tracker. Page tracking aborted', err);
        return;
      }
    }

    const mappedPage = makeMapping(this.trackerName, 'trackPage', e.value);
    if (!mappedPage) {
      logger.debug('Values not found in the mapping. Page tracking aborted', e.value);
      return;
    }

    if (window.braze && typeof window.braze.logCustomEvent === 'function') {
      const trackParams = this.getTrackParams(e);
      const isLoggingSuccessful = window.braze.logCustomEvent(mappedPage.value, trackParams);

      if (!isLoggingSuccessful) {
        logger.debug('Braze page tracking failed', e.actionType, e.value, e.props);
      } else {
        logger.debug('Braze page tracked', e.actionType, e.value, e.props);
      }
    }
  }

  getEndpoint() {
    return getRegionConfig()?.custom?.[getNinjaConfig().siteUrl]?.config?.[TRACKER_ABBREVIATIONS.braze]?.endpoint;
  }

  // Internal function - enable SDK is previously disabled and initialize
  enableAndInitialize(code: string, endpoint: string) {
    this.isDisabled = false;
    if (window.braze?.isDisabled()) {
      window.braze?.enableSDK();
    }
    window.braze?.initialize(code, {
      baseUrl: endpoint,
      enableLogging: getNinjaConfig().environment !== 'production',
      doNotLoadFontAwesome: true,
    });
  }

  // Disable SDK
  cleanup() {
    super.cleanup();
    if (window.braze) {
      window.braze.disableSDK();
      this.isDisabled = true;
    }
  }

  convertString(text: string) {
    if (typeof text === 'string') {
      return (
        text
          .toLowerCase()
          // Replace invalid characters with a dash
          .replace(/[^a-z0-9/_]+/g, '-')
          // Replace spaces with a dash
          .replace(/\s+/g, '-')
          // Collapse repeated dashes into one
          .replace(/-+/g, '-')
      );
    }
    return text;
  }
}
