import { CategoryCodes, isConsentGiven } from '@olxeu-eprivacy-storage/js';
import { getHydraConfig } from '@/config/hydra';
import { getNinjaConfig } from '@/config/ninja';
import { Tracker } from '@/types/general';

const REQUIRED_CATEGORIES_FOR_3RD_PARTY = [CategoryCodes.C0002, CategoryCodes.C0003];

/**
 * Checks for consent for specific tracker
 */
export function hasRequiredConsentFor(tracker: Tracker) {
  // when in native mode no tracking is allowed. The tracking is done by the native app
  if (getNinjaConfig().isNative) {
    return false;
  }
  // Hydra is always allowed
  if (tracker === 'hydra' || !isEprivacyStorageRequired()) {
    return true;
  }
  // 3rd party trackers need C0002 and C0003
  return REQUIRED_CATEGORIES_FOR_3RD_PARTY.every(isConsentGiven);
}

/**
 * Only some regions/countries are required to comply to GDPR.
 * When in native mode, ignore the consent - the native app will handle it
 */
export function isEprivacyStorageRequired() {
  if (getNinjaConfig().isNative) {
    return false;
  }

  return !!getHydraConfig().eprivacy_scope;
}

export function setDefaultGoogleConsents(dataLayerName = 'dataLayer') {
  const dataLayer = window[dataLayerName];
  if (!Array.isArray(dataLayer)) {
    return;
  }

  // we want to make sure it's processed before other items from the dataLayer
  const ga = function ga() {
    // eslint-disable-next-line prefer-rest-params -- ga expects the arguments structure
    dataLayer.unshift(arguments);
  };

  const verb = 'granted';
  // @ts-expect-error -- ga expects the arguments structure
  ga('consent', 'default', {
    ad_storage: verb,
    ad_user_data: verb,
    ad_personalization: verb,
    analytics_storage: verb,
  });
}
