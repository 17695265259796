import { getNinjaConfig } from '@/config/ninja';
import { CUSTOM_EVENTS, GENERAL_EVENTS, INTERNAL_EVENTS } from '@/const/events';
import { LAQUESIS_COOKIE_NAMES } from '@/const/laquesis';
import { cookieStorage } from '@/cookies/cookieStorage';
import { getCookieExpirationDate } from '@/cookies/getCookieExpirationDate';
import { getLQDefinition } from '@/cookies/laquesis/lqstatus';
import { parseLaquesisCookie, parseLaquesisFfCookie } from '@/cookies/laquesis/parse';
import { ninjaBus } from '@/eventbus';
import { getLogger } from '@/logger';
import { state } from '@/ninja/state';
import { BaseTracker } from '@/trackers/BaseTracker';
import { EventHandler } from '@/types/events';
import { triggerCustomEvent } from '@/utils/event';
import { checkResults, overrideLaquesisResultsPush } from './cookieWatcher';
import { reloadLaquesisDefinitions } from './definition';
import { trackExpsAssignment, trackFlagsAssignment, trackImpression } from './laquesisTracking';
import { handlePlaygroundParams } from './playground';
import { setupNativeWindow, setupWindow } from './setupWindow';

const logger = getLogger('Laquesis');

export class Laquesis extends BaseTracker {
  listeners: Record<string, EventHandler>;

  constructor() {
    super();
    this.trackerName = 'laquesis';
    this.listeners = {
      [INTERNAL_EVENTS.DEFINITION_RELOAD]: () => reloadLaquesisDefinitions(),
      [INTERNAL_EVENTS.TRACK_EXP_ASSIGNMENT]: () => trackExpsAssignment(),
      [INTERNAL_EVENTS.TRACK_FLAG_ASSIGNMENT]: () => trackFlagsAssignment(),
      [INTERNAL_EVENTS.TRACK_IMPRESSION]: e => trackImpression(e.props.expName as string, e.props.variantName as string),
    };
  }

  async init() {
    if (!this.isInitialized) {
      if (getNinjaConfig().isNative) {
        setupNativeWindow();
        logger.debug('Native window was set up');
      } else {
        await initLaquesis();
      }
      this.isInitialized = true;
    }

    return this;
  }

  async setupListeners() {
    if (!getNinjaConfig().isNative && navigator.cookieEnabled) {
      super.setupListeners();

      if (!this.isInitialized) {
        await this.init();
      }
    }
    return this;
  }

  async trackEvent() {}
  async trackPage() {}
}

async function initLaquesis() {
  if (!navigator.cookieEnabled) {
    logger.debug('Cookies are disabled. Laquesis initialization aborted');
    return;
  }
  if (!state.currentSession?.sessionLong) {
    logger.debug('No session long available. Laquesis initialization aborted');
    return;
  }

  try {
    const laquesisQaHash = new URL(window.location.href).searchParams.get('laquesisqahash');
    if (laquesisQaHash) {
      logger.debug('Laquesis qa hash detected.');
      handlePlaygroundParams(laquesisQaHash);
    }

    ninjaBus.emit(INTERNAL_EVENTS.INIT_SURVEYS_STORAGE, '');

    checkLaquesisRefresh();
    if (state.isLQRefreshNeeded && !state.playgroundMode) {
      await reloadLaquesisDefinitions();
    } else {
      const expsCookieValue = cookieStorage.get(LAQUESIS_COOKIE_NAMES.LAQUESIS);
      if (expsCookieValue) {
        state.activeExps = parseLaquesisCookie(expsCookieValue, parseLaquesisCookieCallback);
        // New user, the cookie and definition comes from the backend
        if (state.cookieFromLq) {
          state.cookieFromLq = false;
          // Fix for some servers that quotes the cookie when contains @ :: Overwrite laquesis without quotes
          const normalizedCookieValue = expsCookieValue.replace(/^"(.+)"$/, '$1');
          cookieStorage.set(LAQUESIS_COOKIE_NAMES.LAQUESIS, normalizedCookieValue, {
            expires: getCookieExpirationDate(1, 'year'),
            path: '/',
            domain: getNinjaConfig().cookieDomain,
          });
          ninjaBus.emit(GENERAL_EVENTS.TRACK_FEATURE_USAGE, '', { message: 'laquesis/lqonap' });
          trackExpsAssignment();
        }
      }
      const flagsCookieValue = cookieStorage.get(LAQUESIS_COOKIE_NAMES.LAQUESIS_FF);
      if (flagsCookieValue) {
        state.activeFlags = parseLaquesisFfCookie(flagsCookieValue);
      }
    }

    setupWindow();

    // Call function when laquesis is loaded and ready to use
    // THIS IS USED IN react-laquesis!
    if (typeof window.laquesisFunctionsCallback === 'function') {
      logger.debug('Calling laquesisFunctionsCallback()', window.laquesisFunctionsCallback);
      window.laquesisFunctionsCallback!();
    }

    // Handle cookie watcher
    overrideLaquesisResultsPush(parseLaquesisCookieCallback);
    checkResults(parseLaquesisCookieCallback);

    triggerCustomEvent(CUSTOM_EVENTS.LAQUESIS_READY);
    logger.debug('Laquesis initialized');
  } catch (error) {
    logger.error('Laquesis initialization error', error);
    throw error;
  }
}

export function checkLaquesisRefresh() {
  const lqDefinition = getLQDefinition();
  if (lqDefinition.nextRefresh - Date.now() < 0) {
    // Refresh because there is no cookie yet or the definition is expired
    state.isLQRefreshNeeded = true;
  }
  if (lqDefinition.isDefinitionsForUserId !== !!state.userId) {
    // Refresh because there is a mismatch in identifiers used by the LQ definition and the SDK config (sessionLong vs. userId)
    state.isLQRefreshNeeded = true;
    lqDefinition.isDefinitionsForUserId = !lqDefinition.isDefinitionsForUserId;
  }
}

export function parseLaquesisCookieCallback(expName: string, variantName: string) {
  trackImpression(expName, variantName);
  triggerCustomEvent(CUSTOM_EVENTS.LAQUESIS_EXP_IMPRESSION, {
    experiment: expName,
    variant: variantName,
  });
}
