import { MetricType } from 'web-vitals';
import { CategoryCodes, getActiveGroups } from '@olxeu-eprivacy-storage/js';
import { getHydraConfig } from '@/config/hydra';
import { getNinjaConfig } from '@/config/ninja';
import { GENERAL_EVENTS, INTERNAL_EVENTS } from '@/const/events';
import { DEBUG_STREAM, HYDRA_HOST, Platform } from '@/const/general';
import { cleanCookie } from '@/cookies/cleanCookie';
import { ninjaBus } from '@/eventbus';
import { getLogger } from '@/logger';
import { state } from '@/ninja/state';
import { Event } from '@/types/events';
import { getCurrentPath } from '@/utils/browser';
import { ensureEventMeta } from '@/utils/event';
import { makeMapping, makeObjectMapping } from '@/utils/mapping';
import { trackWithBeacon } from '@/utils/track';
import { loadWebVitals } from '@/web-vitals';
import { BaseTracker } from './BaseTracker';

const logger = getLogger('HydraTracker');

export class HydraTracker extends BaseTracker {
  /**
   * Last tracked `cP` value.
   */
  lastTrackedPageName: string = '';
  // Temporary list of events which we allow to be camelCase. This enables push notifications tracking for web
  // TODO: Remove when the pipelines are ready, @suresh.arumugham team
  webPushEvents = ['pushRcv', 'pushOpn', 'pushDis'];

  constructor() {
    super();
    this.trackerName = 'hydra';

    this.listeners = {
      trackEvent: e => this.initAndExecute(() => this.trackEvent(e)),
      trackPage: e => this.initAndExecute(() => this.trackPage(e)),
      trackLinkEvent: e => this.initAndExecute(() => this.trackLinkEvent(e)),
      trackPerformanceEvent: e => this.initAndExecute(() => this.trackPerformanceEvent(e)),

      // Custom events
      [GENERAL_EVENTS.TRACK_WEB_VITAL]: e => this.trackWebVital(ensureEventMeta(e)),
      [GENERAL_EVENTS.TRACK_FEATURE_USAGE]: e => this.trackFeatureUsage(ensureEventMeta(e)),
      [GENERAL_EVENTS.TRACK_SURVEY]: e => this.trackSurvey(ensureEventMeta(e)),
    };
  }

  async init() {
    this.isInitialized = true;
    logger.debug('Hydra tracker initialized');

    return this;
  }

  async doTracking(e: Event) {
    const streamURL = `${HYDRA_HOST}/${getHydraConfig().path}`;
    const urlParams = this.getParams(e);

    if (urlParams) {
      ninjaBus.emit(INTERNAL_EVENTS.CHECK_SURVEYS_FOR_TRIGGER, e.value, e.props, e.meta);
      await trackWithBeacon(streamURL, urlParams);
    }
  }

  async trackEvent(e: Event, isLinkEvent = false) {
    if (!this.isInitialized) {
      await this.init();
    }

    if (isLinkEvent) {
      // link events are the same as normal events, but have a callback
      const callbackFn = e.props.linkCallBack;
      const errorCallbackFn = e.props.linkErrorCallBack;

      try {
        delete e.props.linkCallBack;
        delete e.props.linkErrorCallBack;

        await this.doTracking(e);

        if (typeof callbackFn === 'function') {
          callbackFn();
        }
      } catch (e) {
        if (typeof errorCallbackFn === 'function') {
          errorCallbackFn(e);
        }
      }
    } else {
      await this.doTracking(e);
    }

    logger.debug('Hydra event tracked', e.actionType, e.value, e.props, `isLinkEvent=${isLinkEvent}`);
  }

  async trackPage(e: Event) {
    if (!this.isInitialized) {
      await this.init();
    }

    await this.doTracking(e);

    /**
     * load vitals after first page event. It doesn't matter when the library is loaded as
     * the metrics are stored by the browser and are available at any time.
     * There's a check inside for browser compatibility and loading the library only once
     */
    if (getHydraConfig().vitals && getHydraConfig().vitals_path && state.firstTrackedPageEvent) {
      await loadWebVitals();
    }

    logger.debug('Hydra page tracked', e.actionType, e.value, e.props);
  }

  /**
   * Link events have a callback, which is executed after the tracking call.
   * Otherwise, it's the same as a normal event.
   */
  async trackLinkEvent(e: Event) {
    if (!this.isInitialized) {
      await this.init();
    }
    await this.trackEvent(e, true);

    logger.debug('Hydra link event tracked', e.actionType, e.value, e.props);
  }

  /**
   * Track custom performance event - analogue of web-vitals, but for SPAs.
   * Used explicitly by Horizontals.
   */
  async trackPerformanceEvent(e: Event) {
    if (!getHydraConfig().vitals_path) {
      return false;
    }

    if (!this.isInitialized) {
      await this.init();
    }
    const urlParams = this.getParams(
      {
        ...e,
        actionType: 'trackEvent',
      },
      true
    );

    if (urlParams) {
      const url = `${HYDRA_HOST}/${getHydraConfig().vitals_path}`;

      await trackWithBeacon(url, urlParams);
      logger.debug('Hydra performance event tracked', e.actionType, e.value, e.props);

      ninjaBus.emit(GENERAL_EVENTS.TRACK_FEATURE_USAGE, '', { message: 'hydra/trackPerformanceEvent' });

      return true;
    } else {
      logger.warn('No params for performance event', e, urlParams);
    }

    return false;
  }

  async trackFeatureUsage(e: Event) {
    const streamURL = `${HYDRA_HOST}/${DEBUG_STREAM}`;
    const urlParams = this.getParams(
      {
        ...e,
        actionType: 'trackEvent',
        value: e.value || 'ninja_debug',
        props: {}, // not important, added manually
      },
      true
    );

    if (urlParams) {
      Object.assign(urlParams, {
        eN: 'ninja_debug',
        message: e.props.message as string,
        is_native: getNinjaConfig().isNative,
        git_tag: import.meta.env.__GIT_TAG__,
        git_revision: import.meta.env.__GIT_HASH__,
        git_branch: import.meta.env.__GIT_BRANCH__,
      });

      trackWithBeacon(streamURL, urlParams);

      return true;
    }

    return false;
  }

  async trackWebVital(e: Event) {
    if (getNinjaConfig().isNative) {
      logger.info('Try to track while in Native env');
      return false;
    }

    const streamURL = `${HYDRA_HOST}/${getHydraConfig().vitals_path}`;
    const urlParams = this.getParams(
      {
        ...e,
        actionType: 'trackEvent',
        value: 'web_vital',
        props: {}, // not important, added manually
      },
      true
    );

    if (urlParams) {
      const metric = e.props.metric as MetricType;
      // manually set the name of the event - `web_vital` is not part of the matrix
      Object.assign(urlParams, {
        eN: 'web_vital',
        web_vital_name: metric.name,
        web_vital_value: metric.value,
        web_vital_rating: metric.rating,
        web_vital_page: state.firstTrackedPageEvent,
      });

      trackWithBeacon(streamURL, urlParams);
    }

    return true;
  }

  async trackSurvey(e: Event) {
    const url = `${HYDRA_HOST}/${getHydraConfig().survey_path}`;
    const urlParams = this.getParams(
      {
        ...e,
        actionType: 'trackEvent',
        value: e.value,
      },
      true
    );

    if (urlParams) {
      Object.assign(urlParams, {
        cH: state.currentPlatform || 'web',
        survey_id: e.props.surveyId,
        survey_type: e.props.type,
        survey_page_id: e.props.surveyPageId,
      });

      if (getNinjaConfig().environment !== 'production') {
        urlParams.env = 'dev';
      }
      if (e.props.questionId) {
        urlParams.survey_question_id = e.props.questionId;
      }
      if (e.props.questionValue) {
        urlParams.survey_question_value = e.props.questionValue;
      }

      trackWithBeacon(url, urlParams);
    }
  }

  /**
   * Each request to Hydra should contain the platform property.
   * If it is not `platform`, it is provided in the region config.
   * We save it once and apply it to every tracking request.
   */
  applyPlatform(params: Record<string, any>) {
    // fallback to a default prop name if the region config lacks specific definition
    const property = getHydraConfig().platform_property || 'platform';
    const platform = getNinjaConfig().platform;
    const mappedParams = { ...params };

    // first we try to take the platform defined in getNinjaConfig()
    if (platform == 'm') {
      mappedParams[property] = Platform.Mobile;
      state.currentPlatform = Platform.Mobile;
    } else if (platform === 'd') {
      mappedParams[property] = Platform.Desktop;
      state.currentPlatform = Platform.Desktop;
    } else {
      // Check platform only if we know the field
      if (mappedParams[property]) {
        // Store the platform value if exists and is different
        if (mappedParams[property] !== state.currentPlatform) {
          state.currentPlatform = mappedParams[property];
        }
      } else if (state.currentPlatform) {
        // If there is no platform, use the last value found
        mappedParams[property] = state.currentPlatform;
      }
    }

    return mappedParams;
  }

  convertString(value: string) {
    if (typeof value === 'string') {
      const tmp = this.webPushEvents.includes(value)
        ? value.replace(/[^a-zA-Z0-9/_]/g, '-')
        : value.toLowerCase().replace(/[^a-z0-9/_]/g, '-');

      return tmp.replace(/\s{2,}/g, ' ').replace(/-{2,}/g, '-');
      // return value
      //   .toLowerCase()
      //   .replace(/[^a-z0-9/_]/g, '-')
      //   .replace(/\s{2}/g, ' ')
      //   .replace(/\s/g, '-')
      //   .replace(/---/g, '-')
      //   .replace(/---/g, '-')
      //   .replace(/--/g, '-');
    }
    return value;
  }

  cleanup() {
    super.cleanup();
    const usedCookies = ['onap', 'lqonap'];

    usedCookies.forEach(e => cleanCookie(e));
  }

  /**
   * Merges all different type of tracking params and maps them, according to the config.
   */
  getParams(e: Event, ignoreMappingResult = false) {
    const urlParams: Record<string, unknown> = {};
    const eventNameMapping = makeMapping('hydra', e.actionType, e.value, ignoreMappingResult);

    if (eventNameMapping) {
      const moduleDefaultParams = makeObjectMapping('hydra', this.getDefaultParams(e));
      const params = this.getTrackParams(e, moduleDefaultParams);

      // Convert all values - arrays are converted with JSON and all other values are left to encodeURIComponent
      // `encodeURIComponent([1,2]) == "1,2"`, while `JSON.stringify([1,2]) == "[1,2]"`
      for (const [k, v] of Object.entries(params)) {
        if (Array.isArray(v)) {
          urlParams[k] = JSON.stringify(v);
        } else {
          urlParams[k] = v;
        }
      }

      // set event name
      if (eventNameMapping!.key) {
        urlParams[eventNameMapping!.key] = eventNameMapping!.value;
      }

      if (e.actionType === 'trackPage') {
        state.firstTrackedPageEvent = e.value;
      }

      // triggered events are critical for laquesis
      if (getHydraConfig()?.triggeredEvents?.includes(e.value)) {
        urlParams.rT = 1;
      }

      // make sure platform field is set AFTER all mappings
      return this.applyPlatform(urlParams);
    }

    logger.debug('Unknown event', e.actionType, e.value, e.props);
  }

  /**
   * Gets all default parameters for Hydra
   */
  getDefaultParams(e: Event) {
    const defaultParams: Record<string, unknown> = {};

    // Session
    defaultParams.sl = e.meta?.sessionParams?.sessionLong;
    defaultParams.s = e.meta?.sessionParams?.session;
    defaultParams.cl = e.meta?.sessionParams?.sessionCountLong;
    defaultParams.c = e.meta?.sessionParams?.sessionCount;

    if (e.meta?.sessionParams?.noCookie) {
      defaultParams.nc = 1;
    }

    // Timestamp - should be generated when the event is added to the dataLayer
    defaultParams.t = e.t ?? Date.now();

    // Add consent. Do not track "strictly necessary" category C0001
    defaultParams.consent = getActiveGroups()
      .filter(group => group !== CategoryCodes.C0001)
      .join(',');

    // Discover some params from the url
    if (e.meta?.invite) {
      defaultParams.iv = e.meta.invite;
    }

    // Host
    if (e.meta?.host) {
      defaultParams.host = e.meta.host;
    }

    // Hash
    if (e.meta?.hash) {
      defaultParams.hash = e.meta.hash;
    }

    // Referrer
    if (e.meta?.referrer) {
      defaultParams.referer = e.meta.referrer;
    }

    const hydraConfig = getHydraConfig();
    // Default invite
    defaultParams.ivd = 'olx-' + hydraConfig.params?.cC.toLowerCase() + '_organic';

    if (hydraConfig.params?.rE !== undefined) {
      defaultParams.rE = hydraConfig.params.rE;
    }

    if (e.actionType === 'trackPage') {
      this.lastTrackedPageName = getCurrentPath();
    }

    if (this.lastTrackedPageName) {
      defaultParams.cP = this.lastTrackedPageName;
    }

    if (!getNinjaConfig().unitTest) {
      defaultParams.js = 1;
    }

    return defaultParams;
  }

  /**
   * Maps user properties
   */
  getCustomParams(e: Event) {
    const customParams: Record<string, any> = {};

    // Set custom values with unknown name
    for (const [key, value] of Object.entries(e.props)) {
      // Transform all
      const newValues = makeMapping('hydra', key, value);

      // not found or ignored
      if (!newValues?.key || getHydraConfig().ignoreParams?.includes(newValues.key)) {
        continue;
      }

      /* - trackEvent is no longer a property of the custom params - it is handled in the parent function
       * - we don't care about `hydra.knownParameters` - this will be removed to simplify the config.
       *   The mapping result will be empty if the prop is not known
       * - `params_extra` is no longer supported
       */
      customParams[newValues.key] = encodeURIComponent(newValues.value as any);
    }

    return customParams;
  }
}
