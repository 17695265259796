import { getNinjaConfig } from '@/config/ninja';
import { cleanCookie } from './cleanCookie';

export const cookieRegexCache: Record<string, RegExp> = {};

/**
 * Gets cookie name, based on configs, and cleans up all sub-domain and paths
 */
export function getCookieName(cookieName: string) {
  const ninjaConfig = getNinjaConfig();
  let cname = cookieName;
  let strRegExPattern = cookieRegexCache[cname];

  if (!strRegExPattern) {
    strRegExPattern = new RegExp('\\b' + cname + '\\b', 'gi');
    cookieRegexCache[cname] = strRegExPattern;
  }

  // Fix any problem with the cookies
  if (ninjaConfig.environment === 'production') {
    const count = (document.cookie.match(strRegExPattern) || []).length;

    if (count > 1) {
      // clean cookies only for allowed domains
      cleanCookie(cname, [ninjaConfig.cookieDomain]);
    }
  }

  if (cname === 'onap') {
    if (ninjaConfig.environment !== 'production') {
      cname += '_dev';
    }
  }

  return cname;
}
