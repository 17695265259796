import { NinjaConfig } from '@/types/configs';
import type { NinjaUserConfig } from '@/types/global';
import { configTracking } from './configTracking';

let cookieDomain: string = '';
const environment = configTracking?.environment ?? 'production';

if (environment === 'production') {
  if (configTracking?.siteUrl) {
    const matches = configTracking.siteUrl.match(/^[\w\d-]+\.(.*)/);
    if (matches) {
      cookieDomain = matches[1];
    } else {
      cookieDomain = configTracking.siteUrl;
    }
  }
} else {
  cookieDomain = window.location.hostname || '';
}

window.dataLayer = window.dataLayer || [];

function defaultValue<T extends keyof NinjaUserConfig>(key: T, defaultValue: NinjaUserConfig[T]) {
  if (configTracking && key in configTracking) {
    return configTracking[key]!;
  }

  return defaultValue;
}

const ninjaConfig: NinjaConfig = {
  environment,
  cookieDomain,
  specialNames: ['trackPage', 'trackEvent', 'trackLinkEvent', 'trackPerformanceEvent', 'cleanup'],
  siteUrl: defaultValue('siteUrl', ''),
  debug: defaultValue('debug', import.meta.env.__DEV__ || new URLSearchParams(window.location.search).get('ninjadebug') == '1'),

  /**
   * If the value is `a` or `i`, it means we are in a webview and Ninja should forward all
   * events to the parent application via a custom bridge.
   */
  platform: defaultValue('platform', 'd'),
  isNative: configTracking?.platform === 'a' || configTracking?.platform === 'i',
  dataLayer: window[defaultValue('dataLayer', 'dataLayer')] as any[],
  disablePropertyPropagation: defaultValue('disablePropertyPropagation', false),
  disableDefaultTrackPage: defaultValue('disableDefaultTrackPage', false),
  getEprivacySDK: defaultValue('getEprivacySDK', undefined),
  asyncSurveyStyles: defaultValue('asyncSurveyStyles', false),
  surveyTheme: defaultValue('surveyTheme', undefined),

  // dataLayerDelayed: window[defaultValue('dataLayerDelayed', 'dataLayerDelayed')],
};

export function getNinjaConfig() {
  return ninjaConfig;
}
