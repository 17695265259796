import { getNinjaConfig } from '@/config/ninja';
import { getRegionConfig } from '@/config/region';
import { TRACKER_ABBREVIATIONS } from '@/const/general';
import { setDefaultGoogleConsents } from '@/utils/consent';

export function initGa(token: string, customDataLayer: string) {
  (window as any)[customDataLayer] = (window as any)[customDataLayer] || [];
  window.gtag = function gtag() {
    // eslint-disable-next-line prefer-rest-params -- ga expects the arguments structure
    (window as any)[customDataLayer].push(arguments);
  };

  setDefaultGoogleConsents(customDataLayer);

  const crossDomains = getRegionConfig()?.custom?.[getNinjaConfig().siteUrl].config[TRACKER_ABBREVIATIONS.ga]?.cross_domains;
  if (crossDomains) {
    window.gtag('set', 'linker', { domains: crossDomains });
  }
  window.gtag('js', new Date());
  window.gtag('config', token, { anonymize_ip: true });
}
