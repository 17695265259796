import { TRACKER_ABBREVIATIONS } from '@/const/general';
import { getLogger } from '@/logger';
import { ConfigDetails } from '@/types/configs';
import { getNinjaConfig } from './ninja';
import { getRegionConfig } from './region';

let hydraConfig: ConfigDetails;
const logger = getLogger('configs/hydra');

export function getHydraConfig() {
  try {
    if (!hydraConfig) {
      const regionConfig = getRegionConfig();
      const baseConfig = regionConfig!.config[TRACKER_ABBREVIATIONS.hydra]!; // this is always available
      const siteConfig = regionConfig!.custom?.[getNinjaConfig().siteUrl]?.config[TRACKER_ABBREVIATIONS.hydra];
      hydraConfig = siteConfig ? { ...baseConfig, ...siteConfig } : baseConfig;
    }
  } catch (e) {
    logger.debug('Error getting hydra config', e);
  }
  return hydraConfig;
}
