import { INTERNAL_EVENTS } from '@/const/events';
import { cookieStorage } from '@/cookies/cookieStorage';
import { getLQDefinition, writeLQDefinition } from '@/cookies/laquesis/lqstatus';
import { ninjaBus } from '@/eventbus';
import { getLogger } from '@/logger/logger';
import { state } from '@/ninja/state';
import { getNinjaDefaultParams } from '@/trackers/params/getNinjaDefaultParams';
import { Event } from '@/types/events';
import { SurveySetup, SurveyTrigger, SurveyTriggerConditionRule } from '@/types/surveys';
import { getCurrentPath } from '@/utils/browser';
import { getSurvey, getSurveysWithTriggerEvent } from './storage';

const logger = getLogger('Surveys/conditions');

/**
 * Check if a survey is available to be shown.
 * An assigned survey is always available in playground mode and if it's a feedback survey.
 *
 * Otherwise, the following conditions must be met:
 * - The survey has not been already shown
 * - The user is not in lockout period
 */
export function isSurveyAvailable(id: number) {
  const survey = getSurvey(id);
  if (!survey) {
    logger.debug('Survey not assigned to the current user', id);
    return false;
  }
  if (state.playgroundMode || survey.type === 'feedback') {
    logger.debug('In playground mode or feedback survey', id);
    return true;
  }
  return !isSurveyShown(id) && !isInLockoutPeriod();
}

/**
 * Check if the user is in a lockout period set by another survey
 */
export function isInLockoutPeriod() {
  const lqDefinition = getLQDefinition();
  logger.debug('Checking if in lockout period', Date.now() <= lqDefinition.nextSurveyShow * 1000, lqDefinition.nextSurveyShow);
  return Date.now() <= lqDefinition.nextSurveyShow * 1000;
}

/**
 * Check if this specific survey has been shown to the user
 */
export function isSurveyShown(surveyId: number) {
  if (!surveyId) {
    return false;
  }
  const lqDefinition = getLQDefinition();
  logger.debug('Checking if survey is already shown', surveyId, lqDefinition.showedSurveys);
  return lqDefinition.showedSurveys.includes(surveyId.toString());
}

export async function checkSurveysForTrigger(e: Event) {
  const surveys = getSurveysWithTriggerEvent(e.value);

  if (!surveys.length) {
    logger.debug('No surveys to trigger for the current event', e.value);
    return;
  }

  for (const id of surveys) {
    if (state.triggeredSurvey) {
      logger.debug('Another survey already active');
      break;
    }

    const canShow = canShowSurvey(id, e);
    if (canShow) {
      ninjaBus.emit(INTERNAL_EVENTS.TRIGGER_SURVEY, '', { surveyId: id });
    } else {
      logger.debug('Survey can not be shown');
    }
  }
}

/**
 * Checks if a survey can be shown.
 * An assigned survey can always be shown if it's a feedback survey.
 *
 * Otherwise, the following conditions must be met:
 * - The survey has not been already shown
 * - The user is not in lockout period
 * - The triggers defined for the survey were met (based on the tracked params)
 */
export function canShowSurvey(id: number, e: Event) {
  const survey = getSurvey(id);
  if (!survey) {
    logger.debug('Survey does not exist', id);
    return false;
  }

  // Feedback surveys can always be shown
  if (survey.type === 'feedback') {
    logger.debug('Showing feedback survey', id);
    return true;
  }
  if (!isSurveyAvailable(id)) {
    logger.debug('Survey not available', id);
    return false;
  }

  const matchingTriggers = survey.triggers?.filter(t => t.event_name === e.value) || [];
  // Surveys without any triggers can only be shown by calling laquesisShowSurvey
  if (!matchingTriggers.length) {
    logger.debug('No matching event triggers', survey, e);
    return false;
  }

  // Triggers have OR logic, so a single matching trigger is enough
  const found = matchingTriggers.some(t => checkTriggers(t, e));

  if (!found) {
    logger.debug('No matching property trigger', matchingTriggers, e);
  }

  return found;
}

/**
 * Checks if a given trigger matches the currently tracked params.
 * - AND logic among groups
 * - OR logic among the conditions within each group
 */
export function checkTriggers(trigger: SurveyTrigger, e: Event) {
  // If no conditions are defined, the trigger is always valid
  if (!trigger.conditions?.length) {
    return true;
  }

  const allParams = e.props || {};
  const defaultParams = getNinjaDefaultParams(e);

  allParams.sl = e.meta?.sessionParams.sessionLong;
  allParams.s = e.meta?.sessionParams.session;
  allParams.cl = e.meta?.sessionParams.sessionCountLong;
  allParams.c = e.meta?.sessionParams.sessionCount;
  allParams.cp = decodeURIComponent(getCurrentPath());
  allParams.cc = defaultParams.cC;
  allParams.br = defaultParams.bR;

  // AND logic among groups
  return trigger.conditions.every(group => checkConditionGroup(group, allParams));
}

/**
 * If any condition in the group matches, the group is a match.
 * (OR logic among the conditions)
 */
export function checkConditionGroup(groupConditionRules: SurveyTriggerConditionRule[], params: Record<string, unknown>) {
  return groupConditionRules.some(cond => {
    const value = cond.c ? cookieStorage.get(cond.n) : params[cond.n];
    const result = evaluateCondition(cond, value);
    logger.debug('Evaluating condition', cond, value, result);

    return result;
  });
}

export function evaluateCondition(cond: SurveyTriggerConditionRule, value: unknown) {
  if (cond.o === 'is_null') {
    return value === null || value === undefined;
  }
  if (cond.o === 'is_not_null') {
    return value !== null && value !== undefined;
  }
  if (value === null || value === undefined) {
    return false;
  }

  switch (cond.o) {
    case 'equals':
      return value.toString() === cond.v.toString();
    case 'not_equals':
      return value.toString() !== cond.v.toString();
    // If value can't be parsed to number, Number() will give NaN. Comparing NaNs will always fail
    case 'greater_than':
      return Number(value) > Number(cond.v);
    case 'less_than':
      return Number(value) < Number(cond.v);
    default:
      return false;
  }
}

/**
 * Updates lqDefinition to mark the survey as shown if it's not a feedback survey
 */
export function markSurveyAsShown(survey: SurveySetup) {
  const lqDefinition = getLQDefinition();
  const lockoutInSeconds = survey.config?.next_survey_allowed_in_sec || 0;

  if (state.playgroundMode || isSurveyShown(survey.id)) {
    logger.debug('NOT Mark survey as shown - playground mode or shown already', survey);
    return;
  }

  if (survey.type === 'default') {
    lqDefinition.showedSurveys.push(survey.id.toString());
    lqDefinition.nextSurveyShow = Date.now() / 1000 + lockoutInSeconds;
    writeLQDefinition(lqDefinition);
    logger.debug(`Mark survey as shown, next lockout at ${lqDefinition.nextSurveyShow}`, survey);
  }
}
