export const LQ_EVENTS = {
  TEST_ASSIGNMENT: 'test_assignment',
  TEST_IMPRESSION: 'test_impression',
  FLAG_ASSIGNMENT: 'flag_assignment',
};

export const INTERNAL_EVENTS = {
  DEFINITION_RELOAD: 'laquesis-internal/definitionReload',
  TRACK_EXP_ASSIGNMENT: 'laquesis-internal/trackExpAssignment',
  TRACK_FLAG_ASSIGNMENT: 'laquesis-internal/trackFlagAssignment',
  TRACK_IMPRESSION: 'laquesis-internal/trackImpression',

  ASSIGN_QA_SURVEY: 'laquesis-internal/assignQaSurvey',
  ASSIGN_SURVEYS: 'laquesis-internal/assignSurveys',
  INIT_SURVEYS_STORAGE: 'laquesis-internal/initSurveysStorage',
  SETUP_SURVEYS_WINDOW: 'laquesis-internal/setupSurveysWindow',
  CHECK_SURVEYS_FOR_TRIGGER: 'laquesis-internal/checkSurveysForTrigger',
  TRIGGER_SURVEY: 'laquesis-internal/triggerSurvey',
  TRACK_SURVEY_TRIGGER: 'laquesis-internal/trackSurveyTrigger',
  CLOSE_SURVEY: 'laquesis-internal/closeSurvey',
};
