// Loosely based on https://github.com/lemire/StablePriorityQueue.js/

type Comparator<T> = (a: T, b: T) => number;

interface QueueElement<T> {
  value: T;
  counter: number;
}

export class StablePriorityQueue<T = number> {
  public size: number = 0;
  private array: QueueElement<T>[] = [];
  private runningCounter: number = 0;
  private compare: Comparator<T>;

  constructor(comparator?: Comparator<T>) {
    this.compare = comparator || StablePriorityQueue.defaultComparator;
  }

  private static defaultComparator: Comparator<any> = (a, b) => {
    return a < b ? -1 : a > b ? 1 : 0;
  };

  private stableCompare(a: QueueElement<T>, b: QueueElement<T>): boolean {
    const cmp = this.compare(a.value, b.value);
    return cmp < 0 || (cmp === 0 && a.counter < b.counter);
  }

  private renumber(): void {
    const buffer: T[] = [];
    while (!this.isEmpty()) {
      buffer.push(this.pop()!);
    }
    const size = buffer.length;
    this.runningCounter = 0;

    for (let j = 0; j < size; j++) {
      this.push(buffer[j]);
    }
  }

  public push(myval: T): void {
    const i = this.size;
    if (this.runningCounter + 1 === 0) {
      this.renumber();
    }
    const extendedMyVal: QueueElement<T> = { value: myval, counter: this.runningCounter };
    this.array[this.size] = extendedMyVal;
    this.runningCounter += 1;
    this.size += 1;

    this.bubbleUp(i, extendedMyVal);
  }

  private bubbleUp(i: number, extendedMyVal: QueueElement<T>): void {
    let p, ap;
    while (i > 0) {
      p = (i - 1) >> 1;
      ap = this.array[p];
      if (!this.stableCompare(extendedMyVal, ap)) {
        break;
      }
      this.array[i] = ap;
      i = p;
    }
    this.array[i] = extendedMyVal;
  }

  private bubbleDown(i: number): void {
    const size = this.size;
    const hsize = this.size >>> 1;
    const ai = this.array[i];
    let l, r, bestc;

    while (i < hsize) {
      l = (i << 1) + 1;
      r = l + 1;
      bestc = this.array[l];
      if (r < size) {
        if (this.stableCompare(this.array[r], bestc)) {
          l = r;
          bestc = this.array[r];
        }
      }
      if (!this.stableCompare(bestc, ai)) {
        break;
      }
      this.array[i] = bestc;
      i = l;
    }
    this.array[i] = ai;
  }

  /**
   *  Look at the top of the queue (a smallest element)
   */
  public peek(): T | undefined {
    if (this.size === 0) return undefined;
    return this.array[0].value;
  }

  /**
   * remove the element on top of the heap (a smallest element)
   * runs in logarithmic time
   *
   * If the priority queue is empty, the function returns the
   * "undefined" value.
   * https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/undefined
   *
   * For long-running and large priority queues, or priority queues
   * storing large objects, you may  want to call the trim function
   * at strategic times to recover allocated memory.
   */
  public pop(): T | undefined {
    if (this.size === 0) return undefined;
    const ans = this.array[0];
    if (this.size > 1) {
      this.array[0] = this.array[--this.size];
      this.bubbleDown(0);
    } else {
      this.size -= 1;
    }
    return ans.value;
  }

  /**
   * recover unused memory (for long-running priority queues)
   */
  public trim(): void {
    this.array = this.array.slice(0, this.size);
  }

  public isEmpty(): boolean {
    return this.size === 0;
  }
}
