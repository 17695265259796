import { getNinjaConfig } from '@/config/ninja';
import { getRegionConfig } from '@/config/region';
import { GENERAL_EVENTS } from '@/const/events';
import { TRACKER_ABBREVIATIONS } from '@/const/general';
import { ninjaBus } from '@/eventbus';
import { getLogger } from '@/logger';
import { TASK_PRIORITY, taskQueue } from '@/task-queue';
import { Tracker } from '@/types';
import { Event, EventHandler, UserProps } from '@/types/events';
import { hasRequiredConsentFor } from '@/utils/consent';
import { getTrackParams } from './params';

const logger = getLogger('BaseTracker');

export interface Inittable {
  init(): Promise<unknown>;
}

export interface Setupable {
  listeners: Record<string, EventHandler>;
  setupListeners(): Promise<unknown>;
}

export abstract class BaseTracker implements Inittable, Setupable {
  isInitialized: boolean;
  trackerName: Tracker;
  isInitPushed?: boolean;
  scriptTagId?: string;
  scriptUrl?: string;
  listeners: Record<string, EventHandler>;
  private isListening = false;

  constructor() {
    this.isInitialized = false;
    this.listeners = {};
  }

  abstract init(): Promise<unknown>;
  abstract trackEvent(e: Event, isLinkEvent?: boolean): Promise<void>;
  abstract trackPage(e: Event): Promise<void>;

  async setupListeners() {
    if (!this.isListening) {
      for (const [event, callback] of Object.entries(this.listeners)) {
        ninjaBus.off(event, callback);
        ninjaBus.on(event, callback);
      }
      logger.debug(`${this.trackerName} tracker callbacks set up`);
      this.isListening = true;
    }
    return this;
  }

  cleanup() {
    this.isInitialized = false;
  }

  trackLinkEvent(e: Event) {
    ninjaBus.emit(GENERAL_EVENTS.TRACK_FEATURE_USAGE, '', { message: 'trackers/trackLinkEvent' });
    return this.trackEvent(e, true);
  }

  /**
   * Get token for a tracker from the region config
   */
  getToken() {
    return getRegionConfig()?.custom?.[getNinjaConfig().siteUrl]?.config?.[TRACKER_ABBREVIATIONS[this.trackerName]]?.code;
  }

  /**
   * Get single object containing custom, ninja default and tracker-specific params.
   * They are encoded and mapped to the tracker's matrix
   */
  getTrackParams(event: Event, additionalParams: UserProps = {}) {
    return getTrackParams(this.trackerName, event, additionalParams);
  }

  initAndExecute(fn: () => Promise<any>) {
    taskQueue.enqueue({
      fn: async () => {
        await this.setupListeners();
        await this.init();
      },
      priority: TASK_PRIORITY.HIGH,
    });
    taskQueue.enqueue({
      fn,
    });
  }

  hasRequiredConsent() {
    return hasRequiredConsentFor(this.trackerName);
  }
}
