import { Region, Tracker, TrackerAbbreviation } from '@/types/general';

export const NINJA_CDN = 'https://ninja.data.olxcdn.com';

export const SPLITTER_CDN = 'https://laquesis.data.olxcdn.com';

export const JSQR_URL = 'https://www.unpkg.com/jsqr@1.4.0/dist/jsQR.js';

export const HYDRA_HOST = 'https://tracking.olx-st.com/h/v2';

export const DEBUG_STREAM = 'eu-ninja-debug';

export const LAQUESIS_SURVEY_JS_URL = `${NINJA_CDN}/laquesisSurvey.js`;

export const LAQUESIS_SURVEY_CSS_FILENAME = 'laquesis-survey';

/**
 * This is different from `Channel` - it is the user value that CUs use identify the platform.
 * `userProps[hydraConfig.platform_property]` is set to be one of these values.
 */
export const Platform = {
  Mobile: 'mobile-html5',
  Desktop: 'desktop',
} as const;

/**
 * List of available trackers, per region. This controls which trackers will be bundled for each region.
 * If a tracker is not in this list, but in the region-config json, it will not work.
 * The BE also has a list of trackers. per region. This info needs to be a BE endpoint.
 */
export const REGION_TRACKERS: Record<Region, Tracker[]> = {
  cee: [
    'hydra',
    'laquesis',
    'gtm',
    'ga',
    'braze',
    'facebook',
    // Tracker.Adjust, // apps
  ],
  motors: ['hydra', 'laquesis', 'gtm', 'ga', 'braze', 'facebook'],
  'real-estate': [
    'hydra',
    'laquesis',
    'gtm',
    'ga',
    'braze',
    'facebook',
    // Tracker.Adjust, // apps
  ],
  'verticals-services': ['hydra', 'laquesis', 'gtm', 'ga'],
  frejaml: ['hydra', 'laquesis'],
  'moderation-neo': ['hydra'],
  'moderation-hermes': ['hydra'],
  marketpay: ['hydra', 'laquesis'],
  ciam: ['hydra', 'laquesis'],
  console: ['hydra', 'laquesis'],
} as const;

/**
 * Property, added to each event in the dataLayer. It is the moment when the event was generated.
 */
export const TIMESTAMP_PROP = '$T$';

/**
 * Tracker in the region config use different names than the ones in the code.
 * This is a map to help read that config.
 */
export const TRACKER_ABBREVIATIONS: Record<Tracker, TrackerAbbreviation> = {
  braze: 'BRAZE',
  facebook: 'F',
  ga: 'GA',
  gtm: 'G',
  hydra: 'H',
  laquesis: 'LQ',
  surveys: 'LQS',

  // apps
  adjust: 'ADJUST',
  nr: 'NEWRELIC',
} as const;

export const ONAP_EXPIRATION_IN_DAYS = 365;

export const ONAP_SESSION_ACTIVE_DURATION = 1800 * 1000; // in ms
