import { getNinjaConfig } from '@/config/ninja';
import { GENERAL_EVENTS } from '@/const/events';
import { cleanCookie } from '@/cookies/cleanCookie';
import { cookieStorage } from '@/cookies/cookieStorage';
import { getCookieExpirationDate } from '@/cookies/getCookieExpirationDate';
import { getCookieName } from '@/cookies/getCookieName';
import { ninjaBus } from '@/eventbus';
import { getLogger } from '@/logger';
import { Event } from '@/types/events';
import { loadScript } from '@/utils/browser';
import { setDefaultGoogleConsents } from '@/utils/consent';
import { makeMapping } from '@/utils/mapping';
import { BaseTracker } from './BaseTracker';

const logger = getLogger('GtmTracker');

export class GtmTracker extends BaseTracker {
  cookies: string[];
  isInitPushed = false;
  scriptTagId = 'gtm-script-tag';
  scriptUrl = 'https://www.googletagmanager.com/gtm.js?id=';

  constructor() {
    super();

    this.trackerName = 'gtm';
    this.cookies = ['invite', 'ldTd'];
    this.listeners = {
      trackEvent: e => this.initAndExecute(() => this.trackEvent(e)),
      trackPage: e => this.initAndExecute(() => this.trackPage(e)),
      trackLinkEvent: e => this.initAndExecute(() => this.trackLinkEvent(e)),
    };
  }

  async init() {
    if (!this.hasRequiredConsent()) {
      return this;
    }
    const oldTag = document.getElementById(this.scriptTagId as string);
    this.isInitPushed = !!oldTag;
    (window as any)[`ga-disable-${this.getToken()}`] = true;

    if (!this.isInitPushed) {
      setDefaultGoogleConsents();

      window.dataLayer.push({
        processed: true,
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      try {
        const url = this.scriptUrl + this.getToken();
        await loadScript(url, this.scriptTagId as string);

        this.isInitPushed = true;
        logger.debug('GTM script loaded');
      } catch (err) {
        logger.error('GTM tracker initialization error', err);
        this.isInitialized = false;
        throw err;
      }
      logger.debug('GTM tracker initialized');
    }

    this.isInitialized = true;

    return this;
  }

  async prepareGtmForTracking(e: Event) {
    // this.isInitialized = true;
    const oldTag = document.getElementById(this.scriptTagId as string);
    this.isInitPushed = !!oldTag;
    if (!this.isInitPushed) {
      let useCustomDataLayer = true;
      for (const data of window.dataLayer) {
        if (data.trackPage !== undefined || data.trackEvent !== undefined) {
          useCustomDataLayer = false;
        }
      }
      const trackParams = await this.getCustomParams(e, useCustomDataLayer);
      if (trackParams) {
        window.dataLayer.push({ ...trackParams, processed: true });
      }
    }
    return this.init();
  }

  async trackEvent(e: Event) {
    if (!this.hasRequiredConsent()) {
      this.cleanup();
      logger.debug('No consent from the user. Event tracking aborted', e);
      return;
    }

    if (!this.isInitialized) {
      try {
        await this.prepareGtmForTracking(e);
      } catch (err) {
        logger.error('Failed to initialize GTM tracker. Event tracking aborted', err);
        return;
      }
    }

    const eventName = encodeURIComponent(this.convertString(e.value));
    const mappedEvent = makeMapping(this.trackerName, 'trackEvent', eventName);
    if (!mappedEvent) {
      logger.debug('Values not found in the mapping. Event tracking aborted', e.value);
      return;
    }

    try {
      window.dataLayer.push({
        processed: true,
        event: mappedEvent.value,
      });
      logger.debug('GTM event tracked', e.actionType, eventName, e.props);
    } catch (error) {
      logger.error('Failed to track GTM event', error, e.actionType, eventName, e.props);
    }
  }

  async trackPage(e: Event) {
    if (!this.hasRequiredConsent()) {
      this.cleanup();
      logger.debug('No consent from the user. Page tracking aborted', e);
      return;
    }

    if (!this.isInitialized) {
      try {
        await this.prepareGtmForTracking(e);
      } catch (err) {
        logger.error('Failed to initialize GTM tracker. Page tracking aborted', err);
        return;
      }
    }

    const mappedPage = makeMapping(this.trackerName, 'trackPage', e.value);
    if (!mappedPage) {
      logger.debug('Values not found in the mapping. Page tracking aborted', e.value);
      return;
    }

    try {
      window.dataLayer.push({
        processed: true,
        event: mappedPage.value,
      });
      logger.debug('GTM page tracked', e.actionType, e.value, e.props);
    } catch (error) {
      logger.error('Failed to track GTM page', error, e.actionType, e.value, e.props);
    }
  }

  // GTM can't be unloaded from the page
  cleanup() {
    super.cleanup();
    this.cookies.forEach(cookie => cleanCookie(cookie));
    (window as any)[`ga-disable-${this.getToken()}`] = false;
  }

  convertString(text: string) {
    if (typeof text === 'string') {
      return (
        text
          .toLowerCase()
          // Replace invalid characters with a dash
          .replace(/[^a-z0-9/_]+/g, '-')
          // Replace spaces with a dash
          .replace(/\s+/g, '-')
          // Collapse repeated dashes into one
          .replace(/-+/g, '-')
      );
    }
    return text;
  }

  async getCustomParams(e: Event, useCustomDataLayer: boolean) {
    let trackParams: Record<string, unknown> = {};
    if (useCustomDataLayer) {
      trackParams = this.getTrackParams(e);

      if (e.actionType === 'trackPage' && e.value) {
        trackParams.trackPage = e.value;
      }
    }

    const cookieNameLd = getCookieName('ldTd');

    // Invite
    const inviteParams = await this.handleInviteParams(e);

    if (inviteParams.length > 1) {
      trackParams.invs = inviteParams[1].toLowerCase();
      trackParams.invc = inviteParams[2].toLowerCase();

      ninjaBus.emit(GENERAL_EVENTS.TRACK_FEATURE_USAGE, '', { message: 'GtmTracker/invite params available' });
    }

    // Landing Page
    try {
      if (navigator.cookieEnabled) {
        const landingParams = (cookieStorage.get(cookieNameLd) || '').match(/true/);
        if (landingParams === null) {
          cookieStorage.set(cookieNameLd, 'true', {
            expires: getCookieExpirationDate(1, 'min'),
            path: '/',
            domain: getNinjaConfig().cookieDomain,
          });
          trackParams.landing_page = 'true';
        }
      }
    } catch (error) {
      logger.error('Failed to handle landing page', error, e);
    }

    if (Object.keys(trackParams).length === 0) {
      return null;
    }
    return trackParams;
  }

  async handleInviteParams(e: Event) {
    const cookieNameIv = getCookieName('invite');
    const inviteParams = e.meta?.invite?.match(/^\s*([a-zA-Z0-9-]{1,64})(?:_([a-zA-Z0-9%-]{0,128})).*/);

    try {
      if (navigator.cookieEnabled) {
        if (inviteParams) {
          const date = new Date();
          const value = `"sr=${inviteParams[1]}&cn=${inviteParams[2]}&td=${Math.floor(date.getTime() / 1000)}"`;

          cookieStorage.set(cookieNameIv, value, {
            expires: 30,
            path: '/',
            domain: getNinjaConfig().cookieDomain,
          });
          return inviteParams;
        } else {
          const storedInvite = (cookieStorage.get(cookieNameIv) || '').match(/"sr=([A-Za-z0-9-]+)&cn=([A-Za-z0-9%-]+)&td=[0-9]+"/);
          if (storedInvite) {
            return storedInvite;
          }
        }
      }
    } catch (error) {
      logger.error('Failed to handle invite', error, e);
    }
    return [];
  }
}
