import { Tracker } from '@/types';
import { Event, UserProps } from '@/types/events';
import { makeObjectMapping } from '@/utils/mapping';
import { getNinjaDefaultParams } from './getNinjaDefaultParams';

/**
 * Get single object containing custom, ninja default and tracker-specific params.
 * They are encoded and mapped to the tracker's matrix
 */
export function getTrackParams(trackerName: Tracker, event: Event, additionalParams: UserProps = {}) {
  const customParams = makeObjectMapping(trackerName, event.props);
  const ninjaDefaultParams = makeObjectMapping(trackerName, getNinjaDefaultParams(event));

  // default params can't be overriden
  return {
    ...customParams,
    ...additionalParams,
    ...ninjaDefaultParams,
  } as Record<string, unknown>;
}
