import { LATIN_MAP } from '@/const/latinMap';

export function convertToLatin(s: string) {
  return s.replace(/[^A-Za-z0-9\-_]/g, a => LATIN_MAP[a] || a);
}

/**
 * @see https://gist.github.com/dr-dimitru/9317130
 */
export function minifyHTML(s: string) {
  return s.replace(/([^\S ]\s*| \s{2,})(?=[^<]*(?:<(?!\/?(?:textarea|pre|script)\b)[^<]*)*(?:<(textarea|pre|script)\b| z))/, ' ');
}
