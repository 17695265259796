/**
 * Removes list of properties from an object
 */
export function omit<V, K extends string>(object: V, ...keys: Array<K>): Omit<V, K> {
  const ret = { ...object } as any;
  for (const key of keys) {
    delete ret[key];
  }

  return ret as Omit<V, K>;
}

/**
 * Converts object to query-style string.
 * We use encodeURIComponent instead of URLSearchParams - they generate different outputs.
 */
export function objectToQueryString(obj: Record<string, unknown>) {
  return Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`)
    .join('&');
}

/**
 * Checks if the given object is an JS object.
 */
export function isObject(obj: unknown) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}
