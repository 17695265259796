import { CUSTOM_EVENTS, INTERNAL_EVENTS } from '@/const/events';
import { getLQDefinition } from '@/cookies/laquesis/lqstatus';
import { ninjaBus } from '@/eventbus';
import { nativeHandlerFn } from '@/ninja/native/handler';
import { state } from '@/ninja/state';
import { triggerCustomEvent } from '@/utils/event';

/**
 * Expose Laquesis functions to the window object
 */
export function setupWindow() {
  window.isVariantEnabled = function (testName: string, variantName: string) {
    return isVariantEnabled(testName, variantName);
  };
  window.getLaquesisVariant = function (testName: string) {
    return getLaquesisVariant(testName);
  };
  window.isFeatureEnabled = function (flagName) {
    return isFeatureEnabled(flagName);
  };
  window.laquesisSetUserId = function (id) {
    return laquesisSetUserId(id);
  };
  window.laquesisDropUserId = function () {
    return laquesisDropUserId();
  };
  window.laquesisGetActive = function () {
    const definition = getLQDefinition();

    return {
      experiments: Object.fromEntries(state.activeExps),
      flags: Array.from(state.activeFlags),
      surveys: {
        assigned: state.activeSurveys.map(survey => Number(survey.id)),
        shown: definition.showedSurveys.map(Number),
      },
    };
  };
  ninjaBus.emit(INTERNAL_EVENTS.SETUP_SURVEYS_WINDOW, '');
}

/**
 * Expose Laquesis functions to the window object when using webviews (in Android or iOS)
 */
export function setupNativeWindow() {
  window.getLaquesisVariant = function (testName) {
    return nativeHandlerFn('getLaquesisVariant', testName) as string;
  };
  window.isVariantEnabled = function (testName, variantName) {
    if (window.getLaquesisVariant) {
      const variant = window.getLaquesisVariant(testName);
      return variant === variantName;
    }
    return false;
  };
  window.isFeatureEnabled = function (flagName) {
    return nativeHandlerFn('isFeatureEnabled', flagName) as boolean;
  };
}

function isVariantEnabled(testName: string, variant: string) {
  const expName = testName.toLowerCase();
  const variantName = variant.toLowerCase();

  if (!navigator.cookieEnabled) {
    return false;
  }
  const assignedVariant = state.activeExps.get(expName);
  if (assignedVariant !== variantName) {
    return false;
  }

  ninjaBus.emit(INTERNAL_EVENTS.TRACK_IMPRESSION, '', { expName, variantName });
  triggerCustomEvent(CUSTOM_EVENTS.LAQUESIS_EXP_IMPRESSION, {
    experiment: expName,
    variant: variantName,
  });
  return true;
}

function getLaquesisVariant(testName: string) {
  if (!navigator.cookieEnabled) {
    return null;
  }

  const expName = testName.toLowerCase();
  const variantName = state.activeExps.get(expName);
  if (!variantName) {
    return null;
  }

  ninjaBus.emit(INTERNAL_EVENTS.TRACK_IMPRESSION, '', { expName, variantName });
  triggerCustomEvent(CUSTOM_EVENTS.LAQUESIS_EXP_IMPRESSION, {
    experiment: expName,
    variant: variantName,
  });
  return variantName;
}

function isFeatureEnabled(flagName: string) {
  if (!navigator.cookieEnabled) {
    return false;
  }
  return state.activeFlags.has(flagName.toLowerCase());
}

function laquesisSetUserId(id: number | string) {
  if (id && state.userId !== id) {
    state.userId = id;
    ninjaBus.emit(INTERNAL_EVENTS.DEFINITION_RELOAD, '');
  }
}

function laquesisDropUserId() {
  if (state.userId) {
    state.userId = undefined;
    ninjaBus.emit(INTERNAL_EVENTS.DEFINITION_RELOAD, '');
  }
}
