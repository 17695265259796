import { getNinjaConfig } from '@/config/ninja';
import { getRegionConfig, loadRegionConfig } from '@/config/region';
import { initCookieStorage } from '@/cookies/cookieStorage';
import { getLogger } from '@/logger';
import { TASK_PRIORITY, taskQueue } from '@/task-queue';
import { registerTrackers } from '@/trackers/register';
import { ClickEvent, LinkEvent, PageEvent, PerformanceEvent } from '@/types/events';
import { dataLayer } from './dataLayer';
import { startSession } from './sessionManager';

const logger = getLogger('startup');

async function startNinja() {
  logger.debug('Starting Ninja on', `${import.meta.env.__GIT_BRANCH__}@${import.meta.env.__GIT_HASH__}`, import.meta.env.__GIT_TAG__);

  await loadRegionConfig();
  await initCookieStorage();
  logger.debug('Loaded region config and cookie storage');

  if (getRegionConfig()?.custom?.[getNinjaConfig().siteUrl]) {
    logger.debug('siteUrl found in region config');

    startSession();

    dataLayer.init();
    taskQueue.enqueue({
      fn: () => registerTrackers(),
      priority: TASK_PRIORITY.HIGHEST,
      taskId: 'registerTrackers',
    });
    taskQueue.enqueue({
      fn: () => dataLayer.processDataLayer(),
      priority: TASK_PRIORITY.HIGHEST,
      taskId: 'dataLayer.processDataLayer',
    });
  } else {
    logger.error('No site configuration found for the current site', getNinjaConfig().siteUrl);
  }
}

function trackEvent(eventName: string, params: Record<string, unknown> = {}) {
  if (eventName) {
    const trackParams: ClickEvent = Object.assign({}, params, {
      trackEvent: Array.isArray(eventName) ? eventName : [eventName],
    });
    dataLayer.push(trackParams);
  }
}

function trackPage(name: string, params: Record<string, unknown> = {}) {
  const trackParams: PageEvent = Object.assign({}, params, { trackPage: name });

  dataLayer.push(trackParams);
}

function trackLinkEvent(name: string, params: Record<string, unknown> = {}) {
  const trackParams: LinkEvent = Object.assign({}, params, { trackLinkEvent: name });

  dataLayer.push(trackParams);
}

function trackPerformanceEvent(name: string, params: Record<string, unknown> = {}) {
  const trackParams: PerformanceEvent = Object.assign({}, params, { trackPerformanceEvent: name });

  dataLayer.push(trackParams);
}

queueMicrotask(() => {
  startNinja();
});

export default {
  trackEvent,
  trackPage,
  trackLinkEvent,
  trackPerformanceEvent,
};
