import { getCookieName } from '@/cookies/getCookieName';

export const TEST_DIVIDER = '#';
export const VARIANT_DIVIDER = '@';
export const VALUE_DIVIDER = '|';
export const SECTION_DIVIDER = '!';

export const LAQUESIS_COOKIE_NAMES = {
  LAQUESIS: getCookieName('laquesis'),
  LAQUESIS_FF: getCookieName('laquesisff'),
};

export const PLAYGROUND_COOKIES_EXPIRATION = 120;

export const LQ_SURVEYS_STORAGE_KEY = 'laquesissu';
// old keys
export const LQ_SURVEY_TRIGGERS_STORAGE_KEY = '_lq_surveys_';
export const LQ_SURVEY_TYPES_STORAGE_KEY = '_lq_surveys_type_';

export const SURVEY_LAYOUT = {
  WELCOME: 'welcome',
  THANK_YOU: 'thank_you',
};

export const SURVEY_QUESTION_TYPE = {
  STATIC_TEXT: 'static_text',
  MULTILINE_INPUT: 'multiline_input',
  SINGLE_INPUT: 'single_input',
  CHECKBOX_GROUP: 'checkbox_group',
  RADIO_GROUP: 'radio_group',
  RATING: 'rating',
};

export const ELEMENTS = {
  PREFIX: 'laq-',
  BTN_NEXT: 'laq-next-',
  BTN_BACK: 'laq-back-',
  BTN_CLOSE: 'laq-close-',
  OTHER: 'laq-other-',
  ACTIVE: 'laq-active-',
};
